import { TestimonialsContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useState } from 'react'

import { BlockCarousel } from '@/components/shared/block-carousel'
import { DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { useBreakpoint } from '@/lib/hooks/use-breakpoint'
import { cn } from '@/lib/utils/cn'

import { BlockProps } from '../shared/block-switch'
import { TestimonialItem } from './testimonial-item'

export const TestimonialsBlock = ({
  block,
  blockMeta,
  h,
  isPreview,
}: BlockProps) => {
  const [activeTestimonial, setActiveTestimonial] = useState<number | null>(
    null,
  )
  const breakpoint = useBreakpoint()
  const content = block?.content?.content?.value as TestimonialsContent

  const testimonials = content?.testimonials || []

  const fallback = {
    testimonialText: isPreview ? 'Enter a testimonial' : '',
    name: isPreview ? 'Enter a name' : '',
    subtitle: isPreview ? 'Enter a job title' : '',
    initial: isPreview ? 'John Smith' : '',
  }

  return (
    <>
      <div className="flex size-full flex-col rounded-page-widget-inner">
        <BlockCarousel
          count={testimonials.length}
          viewportClassName="px-block rounded-page-widget-inner"
          options={{
            startIndex:
              (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
          }}
        >
          {testimonials.map((testimonial, index) => {
            return (
              <div
                key={index}
                className={cn('py-block flex size-full', {
                  'pr-fluid-3 max-w-fluid-72': testimonials.length > 1,
                })}
              >
                <button
                  type="button"
                  onClick={() => setActiveTestimonial(index)}
                  className="rounded-page-widget-block cursor-pointer bg-page-card-background border-page-card shadow-page-card flex size-full flex-col justify-between space-y-fluid-1 overflow-hidden p-fluid-3 text-left text-page-card-text"
                >
                  <TestimonialItem
                    fallback={fallback}
                    testimonial={testimonial}
                    textClassName={cn('line-clamp-5 leading-snug', {
                      'line-clamp-7': h === 7,
                      'line-clamp-8': h === 8,
                      'line-clamp-10': h === 9,
                      'line-clamp-12': h === 10,
                      'line-clamp-14': h > 10,
                    })}
                  />
                </button>
              </div>
            )
          })}
        </BlockCarousel>
      </div>

      {activeTestimonial !== null && (
        <ResponsiveSheet
          open={activeTestimonial !== null}
          modal={breakpoint !== 'sm'}
          onOpenChange={(open) => {
            if (!open) {
              setActiveTestimonial(null)
            }
          }}
        >
          <ResponsiveSheetContent
            desktopClassName="max-h-full overflow-auto"
            mobileClassName="w-full max-h-[calc(100vh-100px)] min-w-full overflow-auto"
          >
            <VisuallyHidden>
              <DialogTitle>Testimonials</DialogTitle>
            </VisuallyHidden>
            <div className="w-full px-fluid-5 py-fluid-8">
              <TestimonialItem
                fallback={fallback}
                testimonial={testimonials[activeTestimonial]}
              />
            </div>
          </ResponsiveSheetContent>
        </ResponsiveSheet>
      )}
    </>
  )
}
