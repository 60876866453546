import { PartialMessage } from '@bufbuild/protobuf'
import { ProjectAsset } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowUpRightIcon } from '@fingertip/icons'
import { useTranslation } from 'react-i18next'

import { ButtonDiv } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'
import { getFullWidth } from '../shared/utils'

type Props = {
  siteSlug: string
  domain?: string
  w: number
  h: number
  y: number
  index: number
  asset: PartialMessage<ProjectAsset>
  firstAsset?: PartialMessage<ProjectAsset>
} & Pick<BlockProps, 'handleClick' | 'block' | 'scaleFactor'>

export const ProjectItem = ({
  siteSlug,
  domain,
  asset,
  w,
  h,
  y,
  index,
  block,
  handleClick,
  firstAsset,
  scaleFactor,
}: Props) => {
  const { t } = useTranslation()
  const WIDTH = getFullWidth(w, scaleFactor)

  const title = asset?.title || firstAsset?.title
  const description = asset?.description || firstAsset?.description
  const websiteUrl = asset?.websiteUrl || firstAsset?.websiteUrl

  let lineClampOffset = 0

  if (!!websiteUrl) {
    lineClampOffset += 1
  }

  if (!!title) {
    lineClampOffset += 1
  }

  return (
    <div className="py-block flex size-full flex-col pt-0!">
      <MediaImage
        width={WIDTH}
        height={200}
        media={asset?.media}
        priority={index === 0 && y < 6}
        className="h-fluid-44!"
      />

      {h > 4 && title ? (
        <div className="p-block">
          <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
            {title}
          </div>
        </div>
      ) : (
        <div className="pt-block" />
      )}

      <div className="flex-1">
        {description && (
          <div
            className={cn('px-block mb-fluid-4 text-left line-clamp-1', {
              'line-clamp-2': h === 8 + lineClampOffset,
              'line-clamp-5': h === 9 + lineClampOffset,
              'line-clamp-7': h === 10 + lineClampOffset,
              'line-clamp-10': h === 11 + lineClampOffset,
              'line-clamp-13': h === 12 + lineClampOffset,
              'line-clamp-15': h === 13 + lineClampOffset,
            })}
          >
            {description}
          </div>
        )}
      </div>

      {websiteUrl && (
        <div className="px-block">
          <a
            onClick={() => handleClick?.(block)}
            className="block size-full"
            href={prependUrl(websiteUrl, { siteSlug, domain })}
            target={!!websiteUrl ? '_blank' : undefined}
          >
            <ButtonDiv variant="blockPrimary" size="blockSm">
              {t('visit')}
              <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4" />
            </ButtonDiv>
          </a>
        </div>
      )}
    </div>
  )
}
