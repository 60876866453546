import { useMutation } from '@connectrpc/connect-query'
import { ContactContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { sendSiteContactDetailsPublic } from '@fingertip/creator-proto/gen/fingertip/creator/site_contact/v1/site_contact-SiteContactService_connectquery'
import { ArrowInboxIcon, SendIcon } from '@fingertip/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'sonner'
import { z } from 'zod'

import { InputField } from '@/components/fields/input-field'
import { PhoneField } from '@/components/fields/phone-field'
import { Button } from '@/components/ui/button'
import { DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { RootStoreContext } from '@/lib/stores/root-store'

type Props = {
  content: ContactContent
  next: (
    details: { email?: string; name?: string; phone?: string } | null,
  ) => void
  userDetails: {
    email?: string
    name?: string
    phone?: string
  } | null
  siteSlug: string
}

const schema = z.object({
  email: z.string().email('Email address is required'),
  name: z.string().min(1, 'Name is required'),
  phone: z
    .string()
    .refine(isValidPhoneNumber, { message: 'Invalid phone number' })
    .or(z.literal('')),
})

export type Schema = z.infer<typeof schema>

export const ShareContactDetails = observer(
  ({ content, next, userDetails, siteSlug }: Props) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const {
      publicStore: { setContactDialogStage },
    } = useContext(RootStoreContext)

    const mutationUpsert = useMutation(sendSiteContactDetailsPublic, {
      onSuccess: (result, variables) => {
        toast.success(t('details_sent'))
        if (result.hasAccount) {
          next(null)
        } else {
          next({
            email: variables.email,
            name: variables.name,
            phone: variables.phone,
          })
        }

        setLoading(false)
      },
      onError: (error) => {
        toast.error(error.message)
        setLoading(false)
      },
    })

    const { handleSubmit, control } = useForm<Schema>({
      resolver: zodResolver(schema),
      mode: 'all',
      defaultValues: {
        ...userDetails,
      },
    })

    const handleShareDetails = useCallback(
      (input: Schema) => {
        setLoading(true)
        mutationUpsert.mutate({
          email: input.email,
          name: input.name,
          phone: input.phone,
          siteSlug,
        })
      },
      [mutationUpsert, siteSlug],
    )

    return (
      <>
        <DialogHeader className="w-full py-4">
          <DialogTitle className="text-center">
            {t('share_your_details')}
          </DialogTitle>
          <p className="text-center">
            {t('well_send_your_details')}
            {content.fullName ? ` to ${content.fullName}` : ''}
          </p>
        </DialogHeader>

        <form onSubmit={handleSubmit(handleShareDetails)}>
          <InputField
            control={control}
            type="email"
            name="email"
            label={t('email_address')}
            placeholder="E.g. you@email.com"
          />

          <InputField
            control={control}
            type="text"
            name="name"
            label={t('your_name')}
            placeholder="E.g. John Smith"
          />

          <PhoneField
            control={control}
            name="phone"
            label={t('phone_number_optional')}
          />

          <Button
            type="submit"
            className="mt-4 w-full"
            title={t('save_contact')}
            loading={loading}
          >
            <SendIcon className="mr-2 size-5" />
            {t('send_details')}
          </Button>

          <Button
            type="button"
            className="mt-4 w-full"
            title={t('save_contact')}
            variant="secondary"
            onClick={() => setContactDialogStage('SAVE')}
          >
            <ArrowInboxIcon className="mr-2" />
            {t('save_to_contacts')}
          </Button>
        </form>
      </>
    )
  },
)
