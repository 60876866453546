import { CalendarContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowRightIcon, ClockIcon, MapPinIcon } from '@fingertip/icons'
import { Dialog } from '@radix-ui/react-dialog'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { format } from 'date-fns'
import dynamic from 'next/dynamic'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateDisplaySingle } from '@/components/shared/date-display'
import {
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { cn } from '@/lib/utils/cn'

import { ButtonDiv } from '../../ui/button'
import { BlockProps } from '../shared/block-switch'
import { combineToDate, formatEventTime, formatEventTimeRange } from './utils'

const AddToCalendarButton = dynamic(
  () =>
    import('add-to-calendar-button-react').then(
      (mod) => mod.AddToCalendarButton,
    ),
  { ssr: false },
)

export const CalendarBlock = ({ block, h, w }: BlockProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const content = block?.content?.content?.value as CalendarContent

  const { dateTime, combineStart, timeStart, timeEnd } = useMemo(() => {
    const dateTime = formatEventTimeRange(
      content?.startDate,
      content?.endDate,
      content?.startTime,
      content?.endTime,
    )

    const combineStart = combineToDate(content?.startDate, content?.startTime)
    const combineEnd = combineToDate(content?.endDate, content?.endTime)
    const timeStart = formatEventTime(combineStart)
    const timeEnd = formatEventTime(combineEnd)

    return {
      dateTime,
      combineStart,
      timeStart,
      timeEnd,
    }
  }, [
    content?.endDate,
    content?.endTime,
    content?.startDate,
    content?.startTime,
  ])

  const isNarrow = w <= 2
  const isShort = h <= 4

  return (
    <Dialog modal={true} open={open} onOpenChange={setOpen}>
      <DialogTrigger className="cursor-pointer flex relative size-full flex-col outline-none">
        <div className="p-block flex size-full flex-1 flex-col">
          <div
            className={cn('flex w-full flex-1 grow flex-col', {
              'gap-fluid-1': !isShort,
              'gap-fluid-0.5': isShort && isNarrow,
            })}
          >
            {!isShort && !!combineStart && (
              <div className="mb-fluid-2">
                <DateDisplaySingle date={combineStart} />
              </div>
            )}

            <div className="mb-fluid-1 flex flex-row items-center">
              <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
                {content?.eventTitle}
              </div>
            </div>

            <div className="flex-1">
              {dateTime && (
                <div className="flex items-center text-left">
                  {w > 2 && (
                    <ClockIcon className="mr-fluid-2 size-fluid-4 shrink-0" />
                  )}
                  {timeStart}
                  <ArrowRightIcon className="mx-fluid-px size-fluid-4 shrink-0" />
                  {timeEnd}
                </div>
              )}

              {content?.location && !isShort && (
                <div className="flex items-center justify-start text-left">
                  {!isShort && (
                    <MapPinIcon className="mr-fluid-2 size-fluid-4 shrink-0" />
                  )}
                  <div className="line-clamp-2">{content?.location}</div>
                </div>
              )}

              {content?.description && h > 6 && (
                <div className="mt-fluid-1">
                  <div className="line-clamp-2 shrink-0 text-left">
                    {content.description}
                  </div>
                </div>
              )}
            </div>
          </div>

          <ButtonDiv
            size={isShort ? 'blockSm' : 'block'}
            variant="blockPrimary"
            className={cn({
              'w-full': !isShort,
            })}
          >
            {content?.buttonText || t('add_to_calendar')}
            <ArrowRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
          </ButtonDiv>
        </div>
      </DialogTrigger>

      <DialogContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
        <VisuallyHidden>
          <DialogTitle>Calendar</DialogTitle>
        </VisuallyHidden>

        <div className="p-block flex w-full flex-1 flex-col">
          <div className="flex w-full flex-1 grow flex-col gap-4">
            {!!combineStart && (
              <div className="mb-2">
                <DateDisplaySingle date={combineStart} />
              </div>
            )}

            <div className="mb-1">
              <h2 className="h4 text-xl">{content?.eventTitle}</h2>
            </div>

            <div className="flex flex-col gap-2">
              {dateTime && (
                <div className="flex items-center">
                  <ClockIcon className="mr-2 size-4 shrink-0" />
                  <span>
                    {timeStart}
                    <ArrowRightIcon className="mx-2 size-4 inline shrink-0" />
                    {timeEnd}
                  </span>
                </div>
              )}

              {content?.location && (
                <div className="flex items-start">
                  <MapPinIcon className="mr-2 size-4 shrink-0" />
                  <div>{content?.location}</div>
                </div>
              )}

              {content?.description && (
                <div className="mt-2 text-left">{content.description}</div>
              )}
            </div>

            <div className="mt-4">
              <AddToCalendarButton
                name={content?.eventTitle}
                startDate={
                  content?.startDate
                    ? format(new Date(content.startDate), 'yyyy-MM-dd')
                    : ''
                }
                startTime={content?.startTime}
                endDate={
                  content?.endDate
                    ? format(new Date(content.endDate), 'yyyy-MM-dd')
                    : ''
                }
                endTime={content?.endTime}
                timeZone={content?.timezone || 'currentBrowser'}
                description={content?.description}
                location={content?.location}
                options={['Apple', 'Google', 'iCal', 'Outlook.com']}
                buttonsList
                hideTextLabelButton
                buttonStyle="round"
                lightMode="bodyScheme"
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
