import { CheckFilledIcon, LogoStandaloneIcon } from '@fingertip/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'nextjs-toploader/app'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'sonner'
import { z } from 'zod'

import { InputField } from '@/components/fields/input-field'
import { PasswordField } from '@/components/fields/password-field'
import { PhoneField } from '@/components/fields/phone-field'
import { Button } from '@/components/ui/button'
import { DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { useAttribution } from '@/lib/hooks/use-attribution'
import { getSupabaseClient } from '@/lib/supabase/supabase-client'

type Props = {
  next: () => void
  userDetails: {
    email?: string
    name?: string
    phone?: string
  } | null
  siteSlug: string
  returnTo: string
}

const schema = z.object({
  email: z.string().email('Email address is required'),
  name: z.string().min(1, 'Name is required'),
  phone: z
    .string()
    .refine(isValidPhoneNumber, { message: 'Invalid phone number' })
    .or(z.literal('')),
  password: z.string().min(6, 'Password must be at least 6 characters long'),
})

export type Schema = z.infer<typeof schema>

export const CreateFingertipPage = observer(
  ({ next, userDetails, returnTo }: Props) => {
    const { t } = useTranslation()
    const router = useRouter()
    const supabase = getSupabaseClient()
    const [loading, setLoading] = useState(false)
    const { attributeSignup } = useAttribution()

    const { handleSubmit, control } = useForm<Schema>({
      resolver: zodResolver(schema),
      mode: 'all',
      defaultValues: {
        ...userDetails,
      },
    })

    const handleCreateAccount = useCallback(
      async (input: Schema) => {
        try {
          setLoading(true)

          const { error, data } = await supabase.auth.signUp({
            email: input.email,
            password: input.password,
            options: {
              emailRedirectTo: `/sites`,
              data: {
                name: input.name,
              },
            },
          })

          await attributeSignup(data?.user?.id, input.name, input.email)

          if (error) {
            throw error
          }
          next()
          router.replace(returnTo)
        } catch (error: any) {
          toast.error(error.message)
        } finally {
          setLoading(false)
        }
      },
      [returnTo, supabase.auth, attributeSignup, next, router],
    )

    return (
      <>
        <DialogHeader className="w-full py-4">
          <DialogTitle className="text-center">
            <CheckFilledIcon className="mr-1 inline text-success size-5" />{' '}
            {t('contact_details_sent')}
          </DialogTitle>
          <p className="text-center">
            {t(
              'want_your_own_free_page_and_digital_business_card_complete_sign_up_below',
            )}
          </p>
        </DialogHeader>

        <form onSubmit={handleSubmit(handleCreateAccount)}>
          <InputField
            control={control}
            type="email"
            name="email"
            label={t('email_address')}
            placeholder="E.g. you@email.com"
          />

          <InputField
            control={control}
            type="text"
            name="name"
            label={t('your_name')}
            placeholder={t('eg_john_smith')}
          />

          <PhoneField
            control={control}
            name="phone"
            label={t('phone_number_optional')}
          />

          <PasswordField
            control={control}
            name="password"
            label={t('new_password')}
            placeholder={t('6_characters')}
          />

          <Button
            type="submit"
            className="mt-4 w-full"
            title={t('save_contact')}
            loading={loading}
          >
            <LogoStandaloneIcon height={16} width={19.2} className="mr-2" />
            {t('create_free_fingertip_page')}
          </Button>
        </form>
      </>
    )
  },
)
