import { RatingsContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { BlockProps } from '../shared/block-switch'
import { CustomInner } from './custom-inner'
import { FingertipInner } from './fingertip-inner'
import { GoogleInner } from './google-inner'

export const RatingsBlock = (props: BlockProps) => {
  const content = props.block?.content?.content?.value as RatingsContent

  if (content?.source === 'FINGERTIP') {
    return <FingertipInner {...props} />
  } else if (content?.source === 'CUSTOM') {
    return <CustomInner {...props} />
  }

  return <GoogleInner {...props} />
}
