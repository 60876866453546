import { GalleryContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GalleryItem } from '@/components/blocks/gallery/gallery-item'
import { LightboxGallery } from '@/components/blocks/gallery/lightbox-gallery'
import { BlockCarousel } from '@/components/shared/block-carousel'
import { ConditionalWrapper } from '@/components/shared/conditional-wrapper'
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'

export const GalleryBlock = ({
  siteSlug,
  domain,
  block,
  handleClick,
  w,
  h,
  y,
  viewportWidth,
  blockMeta,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as GalleryContent
  const assets = content?.assets || []
  const [index, setIndex] = useState<number | null>(null)

  return (
    <>
      <div
        className={cn('relative size-full rounded-page-widget-inner', {
          'bg-page-background': !content?.hideBackground,
          'overflow-hidden': assets.length <= 1,
        })}
      >
        <ConditionalWrapper
          condition={assets.length > 1}
          wrapper={(children) => (
            <BlockCarousel
              count={assets.length}
              viewportClassName="rounded-page-widget-inner"
              options={{
                startIndex:
                  (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
              }}
            >
              {children}
            </BlockCarousel>
          )}
        >
          <>
            {assets.map((asset, index) => {
              return (
                <ConditionalWrapper
                  key={index}
                  condition={!!asset?.websiteUrl}
                  wrapper={(children) => (
                    <a
                      onClick={() => handleClick?.(block)}
                      href={prependUrl(asset?.websiteUrl, { siteSlug, domain })}
                      className="block size-full shrink-0"
                      target={!!asset?.websiteUrl ? '_blank' : undefined}
                    >
                      {children}
                    </a>
                  )}
                >
                  {asset?.websiteUrl ? (
                    <div className="relative size-full shrink-0">
                      <GalleryItem
                        w={w}
                        h={h}
                        y={y}
                        asset={asset}
                        index={index}
                        viewportWidth={viewportWidth}
                        scaleFactor={scaleFactor}
                        hideBackground={content?.hideBackground}
                      />
                    </div>
                  ) : (
                    <div
                      className="relative size-full shrink-0 cursor-pointer"
                      onClick={() => setIndex(index)}
                    >
                      <GalleryItem
                        w={w}
                        h={h}
                        y={y}
                        asset={asset}
                        index={index}
                        viewportWidth={viewportWidth}
                        scaleFactor={scaleFactor}
                        hideBackground={content?.hideBackground}
                      />
                    </div>
                  )}
                </ConditionalWrapper>
              )
            })}
          </>
        </ConditionalWrapper>
      </div>

      {index !== null && (
        <Dialog
          open={index !== null}
          onOpenChange={(open) => setIndex(open ? 0 : null)}
        >
          <DialogContent className="max-h-[calc(100vh-100px)] max-w-[100vw] overflow-y-auto p-0 sm:max-w-[90vw]">
            <VisuallyHidden>
              <DialogTitle>{t('gallery')}</DialogTitle>
            </VisuallyHidden>

            <LightboxGallery assets={assets} index={index} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
