import { useCallback } from 'react'
import { toast } from 'sonner'

import { useCello } from '@/lib/cello/use-cello'
import { clientGetUrl } from '@/lib/utils/client-get-url'

export const useAttribution = () => {
  const { getUcc } = useCello()
  const attributeSignup = useCallback(
    async (userId?: string, name?: string, email?: string) => {
      const ucc = await getUcc()
      if (ucc && userId) {
        try {
          const response = await fetch(
            `${clientGetUrl()}/api/cello/attribute-signup`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                userId,
                name,
                email,
                ucc,
              }),
            },
          )

          if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData.message || 'Failed to attribute signup')
          }

          const data = await response.json()
          toast.success('Attribution successful')

          return data
        } catch (error: any) {
          toast.error(error.message || 'Error attributing signup')
        }
      } else {
        toast.error('Missing userId or UCC')
      }
    },
    [getUcc],
  )

  return {
    attributeSignup,
    getUcc,
  }
}
