import { useMutation } from '@connectrpc/connect-query'
import { FileContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { createSiteContact } from '@fingertip/creator-proto/gen/fingertip/creator/site_contact/v1/site_contact-SiteContactService_connectquery'
import { ArrowRightIcon } from '@fingertip/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { z } from 'zod'

import { InputField } from '@/components/fields/input-field'
import { Button } from '@/components/ui/button'
import { useToken } from '@/lib/hooks/use-token'
import { cn } from '@/lib/utils'

const schema = z.object({
  email: z
    .string()
    .email('Invalid email address')
    .min(1, { message: 'Email address is required' }),
})

type Props = {
  w: number
  h: number
  inputClassName?: string
  buttonVariant?: 'blockPrimary' | 'default'
  siteSlug: string
  fileContent: FileContent
  onSuccess: () => void
}

export type Schema = z.infer<typeof schema>

export const EmailForm = ({
  inputClassName,
  siteSlug,
  h,
  fileContent,
  buttonVariant = 'blockPrimary',
  onSuccess,
}: Props) => {
  const { t } = useTranslation()
  const { callOptions } = useToken()
  const isShort = h <= 4

  const mutation = useMutation(createSiteContact, {
    callOptions,
    onSuccess: () => {
      toast.success(t('successfully_subscribed'))
      onSuccess()
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const { control, handleSubmit } = useForm<Schema>({
    resolver: zodResolver(schema),
    mode: 'all',
  })

  const submitHandler = useCallback(
    async (input: Schema) => {
      mutation.mutate({
        siteSlug,
        source: 'DOWNLOADED_FILE',
        context: {
          fileName: fileContent.title,
          fileSrc: fileContent.fileAssetSrc,
        },
        ...input,
      })
    },
    [fileContent.fileAssetSrc, fileContent.title, mutation, siteSlug],
  )

  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      className="flex h-full flex-col justify-between"
    >
      <div className="flex h-full flex-1 flex-col justify-center space-y-2 py-2">
        <InputField
          control={control}
          type="email"
          name="email"
          placeholder="E.g. you@email.com"
          className="mb-0 w-full"
          inputClassName={inputClassName}
        />
      </div>

      <Button
        size={isShort ? 'blockSm' : 'block'}
        variant={buttonVariant}
        className={cn({
          'w-full': !isShort,
        })}
      >
        {t('download_0')}
        <ArrowRightIcon className="ml-2 size-4" />
      </Button>
    </form>
  )
}
