import {
  OpeningHoursContent,
  OpeningHoursMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockCarousel } from '@/components/shared/block-carousel'
import { ConditionalWrapper } from '@/components/shared/conditional-wrapper'
import { cn } from '@/lib/utils'

import { BlockProps } from '../shared/block-switch'
import { OpeningHoursTable } from './opening-hours-table'
import {
  getStatusText,
  transformOpeningHours,
  TWENTY_FOUR_HOUR_LOCALES,
} from './utils'

export const OpeningHoursBlock = ({
  block,
  blockMeta,
  handleClick,
  w,
  h,
}: BlockProps) => {
  const { t, i18n } = useTranslation()
  const use24Hour = TWENTY_FOUR_HOUR_LOCALES.includes(
    i18n.language.split('-')[0],
  )

  const content = block?.content?.content?.value as OpeningHoursContent
  const meta = blockMeta?.meta?.metaContent?.value as OpeningHoursMeta
  const isManual = content?.useManualOverride

  const isAlwaysOpen = useMemo(() => {
    if (content?.useManualOverride) {
      return content?.alwaysOpen
    } else {
      return meta?.locationAlwaysOpen || false
    }
  }, [
    content?.alwaysOpen,
    content?.useManualOverride,
    meta?.locationAlwaysOpen,
  ])

  const days = useMemo(() => {
    if (isManual) {
      return transformOpeningHours({
        monday: content?.monday,
        tuesday: content?.tuesday,
        wednesday: content?.wednesday,
        thursday: content?.thursday,
        friday: content?.friday,
        saturday: content?.saturday,
        sunday: content?.sunday,
      })
    }

    return transformOpeningHours({
      monday: meta?.monday,
      tuesday: meta?.tuesday,
      wednesday: meta?.wednesday,
      thursday: meta?.thursday,
      friday: meta?.friday,
      saturday: meta?.saturday,
      sunday: meta?.sunday,
    })
  }, [
    content?.friday,
    content?.monday,
    content?.saturday,
    content?.sunday,
    content?.thursday,
    content?.tuesday,
    content?.wednesday,
    isManual,
    meta?.friday,
    meta?.monday,
    meta?.saturday,
    meta?.sunday,
    meta?.thursday,
    meta?.tuesday,
    meta?.wednesday,
  ])

  let currentDayIndex = new Date().getDay() - 1

  if (currentDayIndex === -1) {
    currentDayIndex = 6
  }

  const statusText = getStatusText({ days, currentDayIndex, t })
  const title = content?.title || t('opening_hours')
  const showTitle = h > 6

  const slideOuter = cn(
    'flex h-full w-[calc(100%-12px)] max-w-fluid-72 pb-block pr-fluid-3',
    {
      'pt-block': !showTitle,
    },
  )
  const slideInner = cn(
    'rounded-page-widget-block bg-page-card-background border-page-card shadow-page-card flex w-full flex-col justify-center space-y-fluid-3 p-fluid-3 text-left text-page-card-text',
    {
      'p-3': h >= 4 && w >= 3,
    },
  )

  if (isAlwaysOpen) {
    return (
      <div className="flex size-full flex-col rounded-page-widget">
        {showTitle && (
          <div className="p-block">
            <div className="page-heading line-clamp-2 grow text-fluid-lg">
              {title}
            </div>
          </div>
        )}

        <div
          className={cn('size-full px-block rounded-b-page-widget-inner', {
            'rounded-t-page-widget-inner': !showTitle,
          })}
        >
          <div className={cn(slideOuter, 'w-full! max-w-full! pr-0!')}>
            <div className={slideInner}>
              <div className="flex items-center">
                <div
                  className={cn('page-heading', {
                    'text-fluid-5xl': h > 5 && w > 3,
                    'text-fluid-4xl': h <= 5 && w > 3,
                    'text-fluid-2xl': w <= 3,
                  })}
                >
                  {t('open_24_7')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex size-full flex-col rounded-page-widget">
      {showTitle && (
        <div className="p-block">
          <div className="page-heading line-clamp-2 grow text-fluid-lg">
            {title}
          </div>
        </div>
      )}

      <BlockCarousel
        count={3}
        viewportClassName={cn('px-block rounded-b-page-widget-inner', {
          'rounded-t-page-widget-inner': !showTitle,
        })}
      >
        {!content.hideOpenToday && (
          <div className={slideOuter}>
            <ConditionalWrapper
              condition={!isManual}
              wrapper={(children) => (
                <a
                  onClick={() => handleClick?.(block)}
                  href={meta?.url || '#'}
                  target={!!meta?.url ? '_blank' : undefined}
                  className="flex size-full"
                >
                  {children}
                </a>
              )}
            >
              <div className={slideInner}>
                {statusText?.top && (
                  <div className="text-fluid-lg">{statusText.top}</div>
                )}

                <div className="flex items-center">
                  {statusText?.bottom && (
                    <div
                      className={cn('page-heading', {
                        'text-fluid-5xl': h > 5 && w > 3,
                        'text-fluid-4xl': h <= 5 && w > 3,
                        'text-fluid-2xl': w <= 3,
                      })}
                    >
                      {statusText.bottom}
                    </div>
                  )}

                  {statusText?.bottomMeta && (
                    <div className="page-heading ml-fluid-1.5 text-fluid-sm">
                      {statusText.bottomMeta}
                    </div>
                  )}
                </div>
              </div>
            </ConditionalWrapper>
          </div>
        )}

        {h <= 6 && (
          <div className={slideOuter}>
            <ConditionalWrapper
              condition={!isManual}
              wrapper={(children) => (
                <a
                  onClick={() => handleClick?.(block)}
                  href={meta?.url || '#'}
                  target={!!meta?.url ? '_blank' : undefined}
                  className="flex size-full"
                >
                  {children}
                </a>
              )}
            >
              <div className={slideInner}>
                {h > 4 ? (
                  <div className="text-fluid-lg">{t('weekdays')}</div>
                ) : null}

                <OpeningHoursTable
                  h={h}
                  w={w}
                  days={days}
                  type="WEEKDAY"
                  use24Hour={use24Hour}
                />
              </div>
            </ConditionalWrapper>
          </div>
        )}

        {h <= 6 && (
          <div className={slideOuter}>
            <ConditionalWrapper
              condition={!isManual}
              wrapper={(children) => (
                <a
                  onClick={() => handleClick?.(block)}
                  href={meta?.url || '#'}
                  target={!!meta?.url ? '_blank' : undefined}
                  className="flex size-full"
                >
                  {children}
                </a>
              )}
            >
              <div className={slideInner}>
                {h > 4 ? (
                  <div className="text-fluid-lg">{t('weekends')}</div>
                ) : null}

                <OpeningHoursTable
                  h={h}
                  w={w}
                  days={days}
                  type="WEEKEND"
                  use24Hour={use24Hour}
                />
              </div>
            </ConditionalWrapper>
          </div>
        )}

        {h > 6 && days.length > 0 && (
          <div className={slideOuter}>
            <ConditionalWrapper
              condition={!isManual}
              wrapper={(children) => (
                <a
                  onClick={() => handleClick?.(block)}
                  href={meta?.url || '#'}
                  target={!!meta?.url ? '_blank' : undefined}
                  className="flex size-full"
                >
                  {children}
                </a>
              )}
            >
              <div className={slideInner}>
                <OpeningHoursTable
                  h={h}
                  w={w}
                  days={days}
                  type="BOTH"
                  use24Hour={use24Hour}
                />
              </div>
            </ConditionalWrapper>
          </div>
        )}
      </BlockCarousel>
    </div>
  )
}
