import { IconGroupContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { cn } from '@/lib/utils/cn'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'

export const IconGroupBlock = ({
  siteSlug,
  domain,
  block,
  handleClick,
  w,
  h,
  y,
  isPreview,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as IconGroupContent

  const isShort = h <= 4

  let size = 30

  if (w > 3 && h > 6) {
    size = 80
  } else if (w > 2 && h > 4) {
    size = 50
  } else if (w > 1 && h > 2) {
    size = 40
  }

  const icons = useMemo(() => {
    const tmpIcons = isPreview
      ? content?.icons || []
      : (content?.icons || []).filter((item) => !!item.websiteUrl)

    return tmpIcons.map((icon) => ({
      ...icon,
      href: prependUrl(icon.websiteUrl, { siteSlug, domain }),
    }))
  }, [content?.icons, domain, isPreview, siteSlug])

  return (
    <div className="p-block flex size-full flex-col">
      {!isShort && content?.title && (
        <div className="flex w-full flex-1 flex-col">
          <div className="mb-fluid-3 flex flex-row items-center">
            <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
              {content.title}
            </div>
          </div>
        </div>
      )}

      <div
        className={cn({
          'grid-cols-2':
            icons.length > 2 || (icons.length === 2 && w > 2 && h < 7),
          'grid h-full gap-fluid-2': h > 2,
          'flex size-full space-x-fluid-2': h <= 2,
        })}
      >
        {icons.map((icon, index) => (
          <a
            key={index}
            onClick={() => handleClick?.(block, `${icon.title} icon`)}
            href={prependUrl(icon?.href, { siteSlug, domain })}
            target={!!icon?.href ? '_blank' : undefined}
            className="border-page-card bg-page-card-background shadow-page-card rounded-page-widget-block flex w-full items-center justify-center overflow-hidden bg-red-500 text-page-card-icon"
            style={{
              background: icon?.media?.color,
            }}
            title={icon?.title}
          >
            <MediaImage
              width={size}
              height={size}
              media={icon?.media}
              title={icon?.title}
              priority={index === 0 && y < 6}
              className="size-full"
              style={{
                maxHeight: size,
                maxWidth: size,
                borderRadius: 8,
              }}
            />
          </a>
        ))}
      </div>

      {(!icons || icons.length === 0) && <div>{t('icons_are_missing')}</div>}
    </div>
  )
}
