import { StarFilledIcon, StarHalfFilledIcon } from '@fingertip/icons'

import { cn } from '@/lib/utils'

type Props = {
  fill: 'full' | 'half' | 'empty'
  className?: string
}
export const GoogleRatingStar = ({ fill, className }: Props) => {
  return (
    <div className={cn('relative', className)}>
      <StarFilledIcon
        className={cn('absolute left-0 top-0 opacity-30', className)}
      />

      {fill === 'full' && (
        <StarFilledIcon className={cn('absolute left-0 top-0', className)} />
      )}

      {fill === 'half' && (
        <StarHalfFilledIcon
          className={cn('absolute left-0 top-0', className)}
        />
      )}
    </div>
  )
}
