import { SVGProps } from 'react'

type Props = {
  size: number
  pulsating: boolean
  scaleFactor: number
}

export const CustomMapDot = ({ size, scaleFactor }: Props) => {
  return (
    <CustomMapDotRadar
      className="m-auto block"
      width={size * scaleFactor}
      height={size * scaleFactor}
    />
  )
}

export const CustomMapDotRadar = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      fill="none"
      {...props}
    >
      <g transform="translate(200,200)">
        <circle cx={0} cy={0} r={50} fill="#fff" className="drop-shadow-lg" />
        <circle
          cx={0}
          cy={0}
          r={36}
          fill="#147aff"
          className="drop-shadow-sm"
        />
        <circle cx={0} cy={0} r={30} id="map-radar" />
      </g>
    </svg>
  )
}
