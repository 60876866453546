'use client'
import React from 'react'

import { Album } from './album'
import { Artist } from './artist'
import { Audiobook } from './audiobook'
import { Episode } from './episode'
import { Playlist } from './playlist'
import { Show } from './show'
import { Track } from './track'
import { User } from './user'

export type SpotifyPlaybackProps = {
  w: number
  h: number
}

type Props = {
  data: any
} & SpotifyPlaybackProps

export const WebPlayback = ({ data, ...props }: Props) => {
  return (
    <>
      {data.track && <Track track={data?.track} {...props} />}
      {data.playlist && <Playlist playlist={data?.playlist} {...props} />}
      {data.album && <Album album={data?.album} {...props} />}
      {data.artist && <Artist artist={data?.artist} {...props} />}
      {data.show && <Show show={data?.show} {...props} />}
      {data.user && <User user={data?.user} {...props} />}
      {data.episode && <Episode episode={data?.episode} {...props} />}
      {data.audiobook && <Audiobook audiobook={data?.audiobook} {...props} />}
    </>
  )
}
