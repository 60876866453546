import { TeamContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useState } from 'react'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { getInitials } from '@/lib/utils/string-utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'
import { TeamInner } from './team-inner'

export const TeamBlock = ({
  block,
  blockMeta,
  h,
  w,
  isPreview,
}: BlockProps) => {
  const [open, setOpen] = useState(false)
  const content = block?.content?.content?.value as TeamContent
  const teams = content?.teams || []
  const isNarrow = w <= 2

  return (
    <Dialog
      modal={true}
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
      }}
    >
      <DialogTrigger className="cursor-pointer flex size-full outline-hidden!">
        {isNarrow ? (
          <div className="p-block flex size-full flex-col">
            <div className="flex size-full flex-col items-center justify-center">
              {content?.title && (
                <div className="mb-fluid-4">
                  <div className="page-heading line-clamp-2 text-center text-fluid-lg">
                    {content.title}
                  </div>
                </div>
              )}

              <div className="flex -space-x-fluid-4">
                {teams.slice(0, 4).map((team, index) => {
                  if (team?.media?.type) {
                    return (
                      <MediaImage
                        media={team?.media}
                        className="size-fluid-10! min-w-fluid-10 border-2 border-white"
                        width={40}
                        height={40}
                        style={{
                          borderRadius: 9999,
                          overflow: 'hidden',
                        }}
                        title={team?.name || ''}
                        key={index}
                      />
                    )
                  } else {
                    return (
                      <Avatar
                        className="size-fluid-10! min-w-fluid-10 border-2 border-white"
                        key={index}
                      >
                        <AvatarFallback
                          value={getInitials(team?.name || '')}
                          noColor
                        />
                      </Avatar>
                    )
                  }
                })}

                {teams.length > 4 && (
                  <Avatar className="size-10 border-2 border-white">
                    <AvatarFallback value={`+${teams.length - 4}`} noColor />
                  </Avatar>
                )}
              </div>
            </div>
          </div>
        ) : (
          <TeamInner
            teams={teams}
            isPreview={isPreview}
            h={h}
            title={content?.title}
            blockMeta={blockMeta}
          />
        )}
      </DialogTrigger>

      <DialogContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
        <VisuallyHidden>
          <DialogTitle>Team</DialogTitle>
        </VisuallyHidden>

        <div className="w-[calc(100vw-36px)] max-w-[478px]">
          <TeamInner
            teams={teams}
            isPreview={isPreview}
            h={10}
            title={content?.title}
            isModal
            controlClassName="embla__controls--dialog"
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
