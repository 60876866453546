import { PartialMessage } from '@bufbuild/protobuf'
import { RatingsMeta_Review } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { StarIcon } from '@fingertip/icons'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { getInitials } from '@/lib/utils/string-utils'

type Props = {
  review: PartialMessage<RatingsMeta_Review> & { reviewerName: string }
  textClassName?: string
}

export const ReviewItem = ({ review, textClassName }: Props) => {
  return (
    <div>
      <div className="mb-fluid-2 flex items-center">
        <Avatar className="size-fluid-8 min-w-fluid-8">
          <AvatarImage src={review?.profilePhotoUrl} alt={review?.name || ''} />

          <AvatarFallback
            delayMs={300}
            value={getInitials(review?.reviewerName)}
            noColor
          />
        </Avatar>

        <div className="ml-fluid-2 flex items-center">
          {review?.reviewerName && (
            <div className="line-clamp-1">{review.reviewerName}</div>
          )}

          <span className="mx-fluid-2">·</span>

          <span className="mr-fluid-1">{review?.rating || 0}</span>

          <StarIcon className="size-fluid-4" />
        </div>
      </div>

      <div className={textClassName}>{review?.text}</div>
    </div>
  )
}
