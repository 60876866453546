import { PageBreakContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { BlockProps } from '../shared/block-switch'
import { PageBreakStyles, PageBreakSwitch } from './styles/page-break-switch'

export const PageBreakBlock = ({ block }: BlockProps) => {
  const content = block?.content?.content?.value as PageBreakContent

  return (
    <div className="relative flex size-full flex-col items-center justify-center overflow-hidden rounded-page-widget-inner">
      <PageBreakSwitch styleSlug={content?.styleSlug as PageBreakStyles} />
    </div>
  )
}
