import { BannerContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'
import { getFullHeight, getFullWidth } from '../shared/utils'

export const BannerBlock = ({ block, w, h, y, scaleFactor }: BlockProps) => {
  const content = block?.content?.content?.value as BannerContent
  const hasAsset = !!content?.media?.type

  const WIDTH = getFullWidth(w, scaleFactor)
  const HEIGHT = getFullHeight(h, scaleFactor)

  return (
    <div className="flex size-full items-center overflow-hidden rounded-page-widget-inner">
      {hasAsset && (
        <>
          <MediaImage
            media={content?.media}
            className="absolute overflow-hidden rounded-page-widget-inner object-cover"
            width={WIDTH}
            height={HEIGHT}
            priority={y < 6}
            style={{
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
            }}
          />
        </>
      )}

      <div
        className="px-block prose:text-page-body-text prose prose-base z-10 w-full text-page-body-text prose-headings:font-page-heading prose-headings:font-page-heading-weight prose-headings:text-page-body-text prose-p:text-page-body-text prose-a:text-page-body-text prose-a:underline prose-strong:text-page-body-text prose-em:text-page-body-text"
        dangerouslySetInnerHTML={{ __html: content?.text || '' }}
      />
    </div>
  )
}
