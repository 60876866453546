import { SkillItem } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { cn } from '@/lib/utils'

type Props = {
  items: SkillItem[]
  visibleCount: number
}

export const SkillList = ({ items, visibleCount }: Props) => {
  return (
    <>
      {items.map((item, index) => (
        <div
          key={index}
          className={cn(
            'inline-block border border-page-body-text rounded-page-widget-block text-fluid-sm px-fluid-2 py-fluid-1 mb-fluid-1 mr-fluid-1 text-left',
            {
              'opacity-0': index >= visibleCount,
            },
          )}
        >
          <div className="line-clamp-1">{item.title}</div>
        </div>
      ))}
    </>
  )
}
