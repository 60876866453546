import { RefObject, useEffect, useState } from 'react'

type Props = {
  w: number
  h: number
  containerRef: RefObject<HTMLDivElement | null>
  items: any[]
  min?: number
}

export const useOverflow = ({ w, h, containerRef, items, min = 1 }: Props) => {
  const [visibleCount, setVisibleCount] = useState(0)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const checkOverflow = () => {
      let cumulativeWidth = 0
      let rowHeight = 0
      let visibleItemsCount = 0
      let lastTopOffset = 0

      Array.from(container.children).forEach((child: any, index) => {
        const childStyle = getComputedStyle(child)
        const childWidth =
          child.offsetWidth +
          parseInt(childStyle.marginLeft) +
          parseInt(childStyle.marginRight)
        const childHeight =
          child.offsetHeight +
          parseInt(childStyle.marginTop) +
          parseInt(childStyle.marginBottom)

        if (index === 0 || child.offsetTop === lastTopOffset) {
          // Item is in the same row
          cumulativeWidth += childWidth
          rowHeight = Math.max(rowHeight, childHeight)
        } else {
          // Item has wrapped to a new row
          cumulativeWidth = childWidth
          rowHeight +=
            lastTopOffset === 0
              ? childHeight
              : childHeight + parseInt(childStyle.marginTop)
        }

        lastTopOffset = child.offsetTop

        // Check if adding this item would overflow the container
        if (
          cumulativeWidth <= container.offsetWidth &&
          rowHeight <= container.offsetHeight - 8
        ) {
          visibleItemsCount++
        }
      })

      setVisibleCount(visibleItemsCount)
    }

    checkOverflow()
    // Adding items.length ensures the effect reruns when the number of items changes
  }, [w, h, items.length, containerRef])

  return { visibleCount: visibleCount < min ? 0 : visibleCount }
}
