import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

/* eslint-disable @next/next/no-img-element */
import { SpotifyIconBox } from './spotify-icon-box'
import { SpotifyPlaybackProps } from './web-playback'

type Props = {
  user: any
} & SpotifyPlaybackProps

export const User = ({ user }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="p-block flex size-full items-center">
      {user?.images?.[1] && (
        <div className="flex items-center justify-center">
          <a
            href={user?.external_urls?.spotify}
            target="_blank"
            className="relative block"
          >
            <img
              src={user?.images?.[1]?.url}
              alt={`${user?.display_name} user cover`}
              className="rounded-page-widget-block mr-fluid-6 aspect-square w-fluid-24 min-w-fluid-24 object-cover shadow-sm"
              width={100}
              height={100}
              loading="lazy"
            />

            <SpotifyIconBox />
          </a>
        </div>
      )}

      <div className="flex flex-1 flex-col justify-between">
        <div>
          <a
            href={user?.external_urls?.spotify}
            target="_blank"
            className="page-heading line-clamp-1 text-fluid-lg hover:underline"
          >
            {user?.display_name}
          </a>

          {(user?.followers?.total || 0) > 0 ? (
            <p className="mb-fluid-2">
              {user?.followers?.total.toLocaleString()} followers
            </p>
          ) : (
            <div className="mb-fluid-2" />
          )}
        </div>

        <div>
          <Button asChild size="blockSm" variant="blockPrimary">
            <a href={user?.external_urls?.spotify} target="_blank">
              {t('follow')}
            </a>
          </Button>
        </div>
      </div>
    </div>
  )
}
