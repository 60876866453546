import { TwitchEmbedContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { memo } from 'react'

import { SocialIcon } from '@/components/shared-page/social-icon'
import { SocialKeys } from '@/lib/data/socials'

import { BlockProps } from '../shared/block-switch'
import { EmbedBlock } from '../shared/embed-block'

const MemoizedEmbedBlock = memo(EmbedBlock)

export const TwitchEmbedBlock = ({
  block,
  w,
  h,
  context,
  viewportWidth,
  scaleFactor,
}: BlockProps) => {
  const content = block?.content?.content?.value as TwitchEmbedContent
  const showFallback = context === 'SEARCH'

  return (
    <div className="flex h-full items-center justify-center overflow-hidden rounded-page-widget-inner">
      {!showFallback && content?.html && (
        <MemoizedEmbedBlock
          html={content.html}
          w={w}
          h={h}
          color="#000"
          viewportWidth={viewportWidth}
          scaleFactor={scaleFactor}
        />
      )}

      {showFallback && (
        <SocialIcon
          kind={'twitch' as SocialKeys}
          width={30}
          height={30}
          className="transition-all"
        />
      )}
    </div>
  )
}
