import { ServicesContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowRightIcon, SuitcaseWorkIcon } from '@fingertip/icons'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { getFullHeight } from '@/components/blocks/shared/utils'
import { IconBox } from '@/components/shared/icon-box'
import { ButtonDiv } from '@/components/ui/button'
import { useHorizontalVisibleCount } from '@/lib/hooks/use-horizontal-visible-count'
import { cn } from '@/lib/utils'

import { ServicesList } from './services-list'

type Props = {
  h: number
  w: number
  content: ServicesContent
  viewportWidth: number
  scaleFactor: number
}

export const ServicesInner = ({ h, content, scaleFactor }: Props) => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const items = content?.services || []
  const HEIGHT = getFullHeight(h, scaleFactor)
  const isShort = h <= 4
  const hasDescription = !!content?.description

  const { visibleCount, showButton } = useHorizontalVisibleCount({
    containerHeight: HEIGHT,
    linkHeight: 41.5,
    containerRef,
    titleRef,
    items,
    buttonHeight: isShort ? 36 : 48,
    scaleFactor,
  })

  if (h < 4) {
    return (
      <div className="flex size-full flex-row items-center justify-between gap-fluid-1">
        {content?.title && (
          <div className="page-heading line-clamp-2 text-left text-fluid-lg">
            {content.title}
          </div>
        )}

        <ButtonDiv size="blockSm" variant="blockPrimary">
          {t('view_all')}
          <ArrowRightIcon className="ml-2 size-fluid-4" />
        </ButtonDiv>
      </div>
    )
  }

  return (
    <>
      <div ref={titleRef}>
        {h >= (hasDescription ? 6 : 4) && (
          <IconBox className="mb-fluid-3">
            <SuitcaseWorkIcon className="size-fluid-4" />
          </IconBox>
        )}

        {content?.title && (
          <div className="mb-fluid-2 shrink-0">
            <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
              {content.title}
            </div>
          </div>
        )}

        {h >= 4 && content?.description && (
          <div className="mb-fluid-3 shrink-0">
            <div
              className={cn('text-left text-page-body-text', {
                'line-clamp-1': h <= 4,
                'line-clamp-2': h > 4 && h <= 8,
                'line-clamp-4': h > 8,
              })}
            >
              {content.description}
            </div>
          </div>
        )}
      </div>

      <div
        className="flex w-full flex-1 flex-col overflow-hidden"
        ref={containerRef}
      >
        <ServicesList items={items} visibleCount={visibleCount} />
      </div>

      {showButton && (
        <ButtonDiv
          size={isShort ? 'blockSm' : 'block'}
          variant="blockPrimary"
          className={cn({
            'w-full': !isShort,
          })}
        >
          {t('view_all')} {items.length}
          <ArrowRightIcon className="ml-fluid-2 size-fluid-4" />
        </ButtonDiv>
      )}
    </>
  )
}
