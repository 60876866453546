import {
  BookingContent,
  BookingMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import {
  ArrowRightIcon,
  ArrowUpRightIcon,
  Calendar1Icon,
} from '@fingertip/icons'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import dynamic from 'next/dynamic'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { Spinner } from '@/components/shared/spinner'
import { DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import useQueryParams from '@/lib/hooks/use-query-params'
import { RootStoreContext } from '@/lib/stores/root-store'
import { prependUrl } from '@/lib/utils/utils'

import { BlockAssetInner } from '../shared/block-asset-inner'
import { BlockProps } from '../shared/block-switch'

const DynamicBookerPage = dynamic(
  () =>
    import('@/components/booking/booker-page').then((comp) => ({
      default: comp.BookerPage,
    })),
  {
    loading: () => <Spinner />,
  },
)

export const BookingBlock = ({
  siteSlug,
  domain,
  block,
  blockMeta,
  h,
  w,
  y,
  handleClick,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as BookingContent
  const meta = blockMeta?.meta?.metaContent?.value as BookingMeta
  const [open, setOpen] = useState(false)
  const { updateQueryParams } = useQueryParams()

  const {
    bookerStore: { bookingId, setStage, resetData },
  } = useContext(RootStoreContext)

  const onOpenBooking = useCallback(() => {
    if (!meta?.hasEventType) {
      toast.error(
        t('bookings_are_currently_unavailable_please_try_again_later'),
      )
      return
    }

    if (bookingId) {
      setStage('SUCCESS')
    } else {
      resetData() // Clear Data to start fresh
      setStage('SERVICES')
    }
    setOpen(true)
  }, [bookingId, meta?.hasEventType, resetData, setStage, t])

  const resetBooking = useCallback(() => {
    updateQueryParams({
      bookingId: undefined,
      attendanceId: undefined,
      stage: undefined,
    })
    resetData()
  }, [resetData, updateQueryParams])

  const onCloseBooking = useCallback(
    (openValue: boolean) => {
      if (!openValue) {
        resetBooking()
      }
      setOpen(openValue)
    },
    [resetBooking],
  )

  if (content?.source === 'THIRD_PARTY') {
    return (
      <a
        onClick={() => handleClick?.(block, content?.websiteUrl)}
        href={prependUrl(content?.websiteUrl, { siteSlug, domain })}
        target={!!content?.websiteUrl ? '_blank' : undefined}
        className="relative flex size-full"
      >
        <BlockAssetInner
          media={content?.media}
          title={content?.title}
          description={content?.description}
          buttonText={content?.buttonText || t('book_now')}
          icon={<Calendar1Icon className="size-fluid-4" />}
          w={w}
          h={h}
          y={y}
          buttonIcon={
            <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
          }
          scaleFactor={scaleFactor}
        />
      </a>
    )
  }

  return (
    <>
      <ResponsiveSheet open={open} onOpenChange={onCloseBooking} modal={true}>
        <button
          type="button"
          className="flex size-full cursor-pointer text-left"
          onClick={onOpenBooking}
        >
          <BlockAssetInner
            media={content?.media}
            title={content?.title}
            description={content?.description}
            buttonText={content?.buttonText || t('book_now')}
            warning={
              !meta?.hasEventType
                ? t('bookings_are_currently_unavailable')
                : undefined
            }
            icon={<Calendar1Icon className="size-fluid-4" />}
            w={w}
            h={h}
            y={y}
            buttonIcon={
              <ArrowRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
            }
            scaleFactor={scaleFactor}
          />
        </button>

        <ResponsiveSheetContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
          <VisuallyHidden>
            <DialogTitle>Booker</DialogTitle>
          </VisuallyHidden>
          {open && (
            <DynamicBookerPage
              siteSlug={meta?.siteSlug}
              source={`blockId:${block.id}`}
              onRootBack={() => setOpen(false)}
            />
          )}
        </ResponsiveSheetContent>
      </ResponsiveSheet>
    </>
  )
}
