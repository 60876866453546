import { FaqContentFaq } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ChevronRightIcon } from '@fingertip/icons'

import { cn } from '@/lib/utils'

type Props = {
  items: FaqContentFaq[]
  onFaqClick: (index: string) => void
  visibleCount: number
}

export const FaqList = ({ onFaqClick, visibleCount, items }: Props) => {
  return (
    <>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => onFaqClick(String(index))}
          className={cn(
            'text-left bg-page-card-background border-page-card rounded-page-widget-block flex flex-row items-center justify-between p-fluid-2 mb-fluid-2 text-page-card-text',
            {
              'opacity-0': index >= visibleCount,
            },
          )}
        >
          <span className="truncate text-page-card-text">{item.question}</span>

          <ChevronRightIcon className="size-fluid-4 shrink-0 text-page-secondary-button-text" />
        </div>
      ))}
    </>
  )
}
