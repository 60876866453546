import { ProjectContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { ProjectItem } from '@/components/blocks/project/project-item'
import { BlockCarousel } from '@/components/shared/block-carousel'

import { BlockProps } from '../shared/block-switch'

export const ProjectBlock = ({
  siteSlug,
  domain,
  block,
  blockMeta,
  w,
  h,
  y,
  handleClick,
  scaleFactor,
}: BlockProps) => {
  const content = block?.content?.content?.value as ProjectContent

  const filteredAssets = (content?.assets || []).filter(
    (asset) => !!asset?.media,
  )
  const firstAsset = filteredAssets?.[0]

  return (
    <div className="relative size-full rounded-page-widget-inner">
      <BlockCarousel
        count={filteredAssets.length}
        viewportClassName="rounded-page-widget-inner"
        options={{
          startIndex: (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
        }}
      >
        {filteredAssets.map((asset, index) => {
          return (
            <ProjectItem
              w={w}
              h={h}
              y={y}
              asset={asset}
              index={index}
              key={index}
              handleClick={handleClick}
              block={block}
              firstAsset={firstAsset}
              scaleFactor={scaleFactor}
              siteSlug={siteSlug}
              domain={domain}
            />
          )
        })}
      </BlockCarousel>
    </div>
  )
}
