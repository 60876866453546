/* eslint-disable @next/next/no-img-element */
import { PauseIcon, PlayIcon } from '@fingertip/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

import { SpotifyIconBox } from './spotify-icon-box'
import { SpotifyPlaybackProps } from './web-playback'

type Props = {
  artist: any
} & SpotifyPlaybackProps

export const Artist = ({ artist, h }: Props) => {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)
  const [activeTrack, setActiveTrack] = useState(0)
  const audioRef = useRef<null | HTMLAudioElement>(null)

  useEffect(() => {
    audioRef.current = new Audio(artist?.tracks?.[0]?.preview_url)
  }, [artist?.tracks])

  const playAudio = useCallback(
    (index: number) => {
      if (!audioRef.current) {
        return
      }

      audioRef.current.pause()
      audioRef.current.src = artist?.tracks?.[index]?.preview_url
      audioRef.current.play()
      setIsPlaying(true)
      setActiveTrack(index)
    },
    [artist?.tracks],
  )

  const pauseAudio = useCallback((index: number) => {
    if (!audioRef.current) {
      return
    }

    audioRef.current.pause()
    setIsPlaying(false)
    setActiveTrack(index)
  }, [])

  useEffect(() => {
    return () => {
      if (!audioRef.current) {
        return
      }

      audioRef.current.pause()
      setIsPlaying(false)
      setActiveTrack(0)
    }
  }, [])

  const isShort = h <= 4

  return (
    <div className="relative flex size-full flex-col overflow-hidden">
      <div className="p-block mb-fluid-3 flex items-center pb-0!">
        {artist?.images?.[0] && (
          <a
            href={artist?.external_urls?.spotify}
            target="_blank"
            className="relative block"
          >
            <img
              src={artist?.images?.[0]?.url}
              alt={`${artist?.name} artist cover`}
              className="rounded-page-widget-block mr-fluid-6 aspect-square w-fluid-24 min-w-fluid-24 object-cover shadow-sm"
              width={100}
              height={100}
              loading="lazy"
            />

            <SpotifyIconBox />
          </a>
        )}

        <div className="w-[calc(100%-124px)]">
          <div>
            <a
              href={artist?.external_urls?.spotify}
              target="_blank"
              className="page-heading line-clamp-1 text-fluid-lg hover:underline"
            >
              {artist.name}
            </a>
            <p className="mb-fluid-2">{t('top_tracks')}</p>
          </div>

          <Button
            onClick={() =>
              isPlaying ? pauseAudio(activeTrack) : playAudio(activeTrack)
            }
            size="blockSm"
            variant="blockPrimary"
            className="min-w-fluid-24"
          >
            {isPlaying ? (
              <PauseIcon className="mr-fluid-2 size-fluid-4" />
            ) : (
              <PlayIcon className="mr-fluid-2 size-fluid-4" />
            )}
            {isPlaying ? t('pause') : t('play')}
          </Button>
        </div>
      </div>

      {!isShort && (
        <div className="p-block overflow-y-auto rounded-b-page-widget pb-0!">
          {(artist?.tracks || []).map((track: any, index: number) => (
            <button
              type="button"
              key={index}
              className="group/artist mb-fluid-2 flex w-full cursor-pointer flex-row items-center justify-between py-fluid-1 text-left"
              onClick={() =>
                isPlaying && activeTrack === index
                  ? pauseAudio(index)
                  : playAudio(index)
              }
            >
              <div className="flex items-center">
                {isPlaying && activeTrack === index ? (
                  <PauseIcon className="mr-fluid-2 size-fluid-4" />
                ) : (
                  <>
                    <div className="mr-fluid-2 hidden group-hover/artist:block">
                      <PlayIcon className="size-fluid-4" />
                    </div>

                    <div className="ml-fluid-0.5 min-w-fluid-5 text-fluid-sm opacity-70 group-hover/artist:hidden">
                      {index + 1}
                    </div>
                  </>
                )}

                <div className="line-clamp-1">{track?.name}</div>
              </div>

              <div className="ml-fluid-2 shrink-0 text-nowrap text-right opacity-70">
                {formatDuration(track?.duration_ms)}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

const formatDuration = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 60000)
  const seconds = Math.round((milliseconds % 60000) / 1000)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}
