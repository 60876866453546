import {
  WeatherContent,
  WeatherMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import NextImage from 'next/image'
import { useTranslation } from 'react-i18next'

import { cn } from '@/lib/utils'

import { BlockProps } from '../shared/block-switch'

export const WeatherBlock = ({ block, blockMeta, h, w }: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as WeatherContent
  const meta = blockMeta?.meta?.metaContent?.value as WeatherMeta

  const icon = meta?.weatherIcon

  if (!meta?.mainTemp) {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('weather_is_missing')}</p>
      </div>
    )
  }

  let size = 100

  if (w > 2 && h <= 4) {
    size = 125
  } else if (w > 2 && h > 4) {
    size = 150
  }

  return (
    <div
      className={cn(
        'relative size-full overflow-hidden rounded-page-widget-inner',
        {
          'bg-black text-white': icon.endsWith('n'),
          'bg-white text-black': !icon.endsWith('n'),
        },
      )}
    >
      {icon && (
        <div className="absolute -right-0 top-1/2 -translate-y-1/2">
          <NextImage
            src={`/weather/${icon}.png`}
            alt={t('weather')}
            width={size}
            height={size}
          />
        </div>
      )}

      <div className="p-block relative flex size-full flex-col justify-center">
        <div className="flex flex-col space-y-fluid-1">
          {meta?.name && <div>{meta?.name}</div>}

          {meta?.mainTemp && (
            <div
              className={cn({
                'text-fluid-5xl': h > 4,
                'text-fluid-3xl': h <= 4,
              })}
            >
              {Math.round(meta?.mainTemp)}º
              {content?.units === 'imperial' ? 'f' : 'c'}
            </div>
          )}

          {meta?.weatherMain && <div>{meta?.weatherMain}</div>}
        </div>
      </div>
    </div>
  )
}
