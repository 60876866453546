import { TeamItem } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useTranslation } from 'react-i18next'

import { BlockCarousel } from '@/components/shared/block-carousel'
import { SocialIcon } from '@/components/shared-page/social-icon'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { SocialKeys } from '@/lib/data/socials'
import { cn } from '@/lib/utils/cn'
import { getInitials } from '@/lib/utils/string-utils'
import { socialUrl } from '@/lib/utils/utils'

import { MediaImage } from '../shared/media-image'

type Props = {
  isPreview?: boolean
  h: number
  teams: TeamItem[]
  isModal?: boolean
  title?: string
  controlClassName?: string
  isDialog?: boolean
  blockMeta?: any
}

export const TeamInner = ({
  isPreview,
  h,
  teams,
  isModal,
  title,
  controlClassName,
  blockMeta,
}: Props) => {
  const { t } = useTranslation()
  const fallbackBio = isPreview ? t('enter_a_bio') : ''
  const fallbackName = isPreview ? t('enter_a_name') : ''
  const fallbackJobTitle = isPreview ? t('enter_a_job_title') : ''
  const fallbackInitial = isPreview ? t('john_smith') : ''
  const isTall = h >= 7

  return (
    <BlockCarousel
      count={teams.length}
      viewportClassName="rounded-page-widget-inner"
      controlClassName={controlClassName}
      options={{
        startIndex: (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
      }}
    >
      {teams.map((team, index) => {
        return (
          <div key={index} className="flex size-full flex-col justify-center">
            <div className="p-block">
              {title && h > 7 && (
                <div className="mb-fluid-4">
                  <div
                    className={cn(
                      'page-heading line-clamp-2 text-center text-fluid-lg',
                      {
                        'text-foreground!': isModal,
                      },
                    )}
                  >
                    {title}
                  </div>
                </div>
              )}

              <div
                className={cn(
                  'mt-fluid-4 flex items-center justify-center pb-fluid-4',
                  {
                    'flex-col': isTall,
                  },
                )}
              >
                <div
                  className={cn('flex items-center', {
                    'flex-col': isTall,
                  })}
                >
                  {team?.media?.type ? (
                    <MediaImage
                      media={team?.media}
                      className={cn({
                        'size-fluid-14! min-w-fluid-14 mb-fluid-2': isTall,
                        'size-fluid-10! min-w-fluid-10': !isTall,
                      })}
                      width={56}
                      height={56}
                      style={{
                        borderRadius: 9999,
                        overflow: 'hidden',
                      }}
                      title={team?.name || ''}
                    />
                  ) : (
                    <Avatar
                      className={cn('size-fluid-10', {
                        'size-fluid-14 mb-fluid-2': isTall,
                      })}
                    >
                      <AvatarFallback
                        value={getInitials(team?.name || fallbackInitial)}
                        noColor
                      />
                    </Avatar>
                  )}

                  <div
                    className={cn('ml-fluid-2 flex flex-col', {
                      'ml-fluid-2': isTall,
                    })}
                  >
                    <div
                      className={cn('truncate', {
                        'text-center': isTall,
                        'text-foreground!': isModal,
                      })}
                    >
                      {team?.name || fallbackName}
                    </div>

                    <div
                      className={cn(
                        'line-clamp-2 text-fluid-sm opacity-70 mt-fluid-1',
                        {
                          'text-center': isTall,
                          'text-foreground!': isModal,
                        },
                      )}
                    >
                      {team?.jobTitle || fallbackJobTitle}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={cn('justify-center', {
                  'px-block': teams.length > 1,
                })}
              >
                <p
                  className={cn('text-center text-fluid-sm', {
                    'line-clamp-[10]': !isModal,
                  })}
                >
                  {team?.bio || fallbackBio}
                </p>
              </div>

              {(team?.socials || []).length > 0 && (
                <div className="px-block mt-fluid-3 flex flex-row items-center justify-center space-x-fluid-1">
                  {(team?.socials || []).map((socialUrlItem, index) => (
                    <a
                      key={index}
                      href={socialUrl({
                        websiteUrl: socialUrlItem?.websiteUrl,
                        key: socialUrlItem?.key,
                      })}
                      target={
                        !!socialUrlItem?.websiteUrl ? '_blank' : undefined
                      }
                    >
                      <SocialIcon
                        kind={socialUrlItem?.key as SocialKeys}
                        className={cn(
                          'size-fluid-4 text-page-body-text transition-all',
                          {
                            'text-foreground!': isModal,
                          },
                        )}
                      />
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </BlockCarousel>
  )
}
