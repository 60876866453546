import { ArrowUpRightIcon } from '@fingertip/icons'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'

type Props = {
  lng: number
  lat: number
  size: 'small' | 'medium' | 'large'
}

export const GetDirectionsButton = ({ lng, lat, size }: Props) => {
  const { t } = useTranslation()

  const handleGetDirections = (event: any) => {
    event.preventDefault()
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
      '_blank',
    )
  }
  return (
    <Button
      onClick={handleGetDirections}
      variant="blockSecondary"
      size="blockSm"
      className={cn({
        'p-fluid-1! h-auto!': size === 'small',
      })}
    >
      {size !== 'small' && <span>{t('directions')}</span>}
      <ArrowUpRightIcon
        className={cn('size-fluid-4', {
          'ml-2': size !== 'small',
        })}
      />
    </Button>
  )
}
