/* eslint-disable @next/next/no-img-element */
import { PauseIcon, PlayIcon } from '@fingertip/icons'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

import { SpotifyIconBox } from './spotify-icon-box'
import { SpotifyPlaybackProps } from './web-playback'

type Props = {
  show: any
} & SpotifyPlaybackProps

export const Show = ({ show, h }: Props) => {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)
  const [activeTrack, setActiveTrack] = useState(0)
  const audioRef = useRef<null | HTMLAudioElement>(null)

  useEffect(() => {
    audioRef.current = new Audio(show?.episodes?.items?.[0]?.audio_preview_url)
  }, [show?.episodes?.items])

  const playAudio = useCallback(
    (index: number) => {
      if (!audioRef.current) return
      audioRef.current.pause()
      audioRef.current.src = show?.episodes?.items?.[index]?.audio_preview_url
      audioRef.current.play()
      setIsPlaying(true)
      setActiveTrack(index)
    },
    [show?.episodes?.items],
  )

  const pauseAudio = useCallback((index: number) => {
    if (!audioRef.current) return
    audioRef.current.pause()
    setIsPlaying(false)
    setActiveTrack(index)
  }, [])

  useEffect(() => {
    return () => {
      if (!audioRef.current) return
      audioRef.current.pause()
      setIsPlaying(false)
      setActiveTrack(0)
    }
  }, [])

  const isShort = h <= 6

  return (
    <div className="relative flex size-full flex-col overflow-hidden">
      <div className="p-block mb-fluid-3 flex items-center pb-0!">
        {show?.images?.[0] && (
          <a
            href={show?.external_urls?.spotify}
            target="_blank"
            className="relative block"
          >
            <img
              src={show?.images?.[0]?.url}
              alt={`${show?.name} show cover`}
              className="rounded-page-widget-block mr-fluid-6 aspect-square w-fluid-24 min-w-fluid-24 object-cover shadow-sm"
              width={100}
              height={100}
              loading="lazy"
            />

            <SpotifyIconBox />
          </a>
        )}

        <div className="w-[calc(100%-124px)]">
          <div>
            <a
              href={show?.external_urls?.spotify}
              target="_blank"
              className="page-heading line-clamp-2 text-fluid-lg hover:underline"
            >
              {show?.name}
            </a>
            <p className="mb-fluid-2 line-clamp-2 text-fluid-sm">
              {show?.description}
            </p>
          </div>

          <Button
            onClick={() =>
              isPlaying ? pauseAudio(activeTrack) : playAudio(activeTrack)
            }
            size="blockSm"
            variant="blockPrimary"
            className="min-w-fluid-24"
          >
            {isPlaying ? (
              <PauseIcon className="mr-fluid-2 size-fluid-4" />
            ) : (
              <PlayIcon className="mr-fluid-2 size-fluid-4" />
            )}
            {isPlaying ? t('pause') : t('play')}
          </Button>
        </div>
      </div>

      {!isShort && (
        <div className="p-block overflow-y-auto rounded-b-page-widget pb-0!">
          {(show?.episodes?.items || [])
            .filter((_item: any, index: number) => index < 10)
            .map((item: any, index: number) => (
              <button
                type="button"
                key={index}
                className="group/show mb-fluid-2 flex w-full cursor-pointer flex-row items-center justify-between py-fluid-1 text-left"
                onClick={() =>
                  isPlaying && activeTrack === index
                    ? pauseAudio(index)
                    : playAudio(index)
                }
              >
                <div className="flex items-center">
                  {isPlaying && activeTrack === index ? (
                    <PauseIcon className="mr-fluid-2 size-fluid-4" />
                  ) : (
                    <>
                      <div className="mr-fluid-2 hidden group-hover/show:block">
                        <PlayIcon className="size-fluid-4" />
                      </div>

                      <div className="ml-fluid-0.5 min-w-fluid-5 text-fluid-sm opacity-70 group-hover/show:hidden">
                        {index + 1}
                      </div>
                    </>
                  )}

                  <div>
                    <div className="line-clamp-2 leading-snug">
                      {item?.name}
                    </div>
                  </div>
                </div>

                {item?.release_date && (
                  <div className="ml-fluid-3 shrink-0 text-nowrap text-right text-fluid-sm opacity-70">
                    <div>{format(new Date(item.release_date), 'MMM d')}</div>
                  </div>
                )}
              </button>
            ))}
        </div>
      )}
    </div>
  )
}
