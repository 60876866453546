import { TextContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { useBreakpoint } from '@/lib/hooks/use-breakpoint'
import { cn } from '@/lib/utils/cn'

import { BlockProps } from '../shared/block-switch'
import { getFullHeight } from '../shared/utils'

export const TextBlock = ({ block, h, scaleFactor }: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as TextContent
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const breakpoint = useBreakpoint()
  const proseClass = cn(
    'prose prose-base w-full p-block prose-headings:font-page-heading-weight prose-headings:font-page-heading',
    {
      'text-page-text prose-headings:text-page-text prose-p:text-page-text prose-a:underline prose-a:text-page-text prose-strong:text-page-text prose-em:text-page-text':
        !!content?.hideBackground,
      'text-page-body-text prose-headings:text-page-body-text prose-p:text-page-body-text prose-a:underline prose-a:text-page-body-text prose-strong:text-page-body-text prose-em:text-page-body-text':
        !content?.hideBackground,
    },
  )
  const isScrolling = useCallback(
    (e: HTMLElement) => {
      return e.clientHeight < e.scrollHeight - 10 * scaleFactor
    },
    [scaleFactor],
  )

  const isShort = h <= 4
  const showViewMore = ref.current && isScrolling(ref.current)

  const HEIGHT = getFullHeight(h, scaleFactor)

  let gradient = `linear-gradient(to bottom, white ${
    ((HEIGHT - 56 * scaleFactor) / HEIGHT) * 100
  }%, transparent ${((HEIGHT - 36 * scaleFactor) / HEIGHT) * 100}%)`

  if (isShort) {
    gradient = `linear-gradient(to bottom, white 50%, transparent 100%)`
  }

  return (
    <>
      <div
        className="relative flex size-full items-center justify-center overflow-hidden rounded-page-widget-inner"
        title={t('click_to_view_more')}
      >
        <div
          ref={ref}
          className={cn(proseClass, 'overflow-y-clip max-h-full', {
            'overflow-hidden': isShort,
          })}
          style={{
            WebkitMaskImage: showViewMore ? gradient : 'none',
            maskImage: showViewMore ? gradient : 'none',
          }}
          dangerouslySetInnerHTML={{ __html: content?.text || '' }}
        />

        {showViewMore && (
          <button
            className="absolute bottom-0 flex cursor-pointer w-full items-center justify-start text-left"
            onClick={() => {
              setDialogOpen(!dialogOpen)
            }}
            type="button"
          >
            <span
              className={cn('px-block pb-block underline', {
                'pb-fluid-1': isShort,
                'text-page-text': !!content?.hideBackground,
                'text-page-body-text': !content?.hideBackground,
              })}
            >
              {t('view_more')}
            </span>
          </button>
        )}
      </div>

      <ResponsiveSheet
        open={dialogOpen}
        modal={breakpoint !== 'sm'}
        onOpenChange={(open) => {
          setDialogOpen(open)
        }}
      >
        <ResponsiveSheetContent
          desktopClassName="max-h-full overflow-auto"
          mobileClassName="w-full max-h-[calc(100vh-100px)] min-w-full overflow-auto"
        >
          <VisuallyHidden>
            <DialogTitle>Text</DialogTitle>
          </VisuallyHidden>
          <div
            className="prose prose-base w-full px-fluid-5 py-fluid-8 text-foreground prose-headings:text-foreground prose-p:text-foreground prose-a:text-foreground prose-a:underline prose-strong:text-foreground prose-em:text-foreground"
            dangerouslySetInnerHTML={{ __html: content?.text || '' }}
          />
        </ResponsiveSheetContent>
      </ResponsiveSheet>
    </>
  )
}
