import { SocialIconGroupContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { sentenceCase } from 'change-case'
import { useTranslation } from 'react-i18next'

import { SocialIcon } from '@/components/shared-page/social-icon'
import { SocialKeys } from '@/lib/data/socials'
import { cn } from '@/lib/utils/cn'
import { socialUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'

export const SocialIconGroupBlock = ({
  block,
  handleClick,
  w,
  h,
  isPreview,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as SocialIconGroupContent

  const isShort = h <= 4

  let size = 30

  if (w > 3 && h > 6) {
    size = 80
  } else if (w > 2 && h > 4) {
    size = 50
  } else if (w > 1 && h > 2) {
    size = 40
  }

  const socialUrls = isPreview
    ? content?.socialIconGroupItems || []
    : (content?.socialIconGroupItems || []).filter((item) => !!item.websiteUrl)

  return (
    <div className="p-block flex size-full flex-col overflow-auto">
      {!isShort && content?.title && (
        <div className="flex w-full flex-1 flex-col">
          <div className="mb-fluid-3 flex flex-row items-center">
            <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
              {content.title}
            </div>
          </div>
        </div>
      )}

      {socialUrls.length === 1 && (
        <a
          onClick={() =>
            handleClick?.(
              block,
              `${sentenceCase(socialUrls[0].key)} social icon`,
            )
          }
          href={socialUrl({
            websiteUrl: socialUrls?.[0]?.websiteUrl,
            key: socialUrls?.[0]?.key,
          })}
          target={!!socialUrls?.[0]?.websiteUrl ? '_blank' : undefined}
          title={block.name}
          className="flex size-full items-center justify-center rounded-page-widget-inner"
        >
          <SocialIcon
            kind={socialUrls[0].key as SocialKeys}
            width={size}
            height={size}
            className="transition-all"
          />
        </a>
      )}

      {socialUrls.length > 1 && (
        <div
          className={cn({
            'grid-cols-2':
              w <= 2 &&
              (socialUrls.length > 2 ||
                (socialUrls.length === 2 && w > 2 && h < 7)),
            'grid-cols-3':
              w > 2 &&
              (socialUrls.length > 2 ||
                (socialUrls.length === 2 && w > 2 && h < 7)),
            'grid h-full gap-fluid-2': h > 2,
            'flex size-full space-x-fluid-2': h <= 2,
          })}
        >
          {socialUrls.map((socialUrlItem, index) => (
            <a
              key={index}
              onClick={() =>
                handleClick?.(
                  block,
                  `${sentenceCase(socialUrlItem.key)} social icon`,
                )
              }
              href={socialUrl({
                websiteUrl: socialUrlItem?.websiteUrl,
                key: socialUrlItem?.key,
              })}
              target={!!socialUrlItem?.websiteUrl ? '_blank' : undefined}
              className="border-page-card bg-page-card-background shadow-page-card rounded-page-widget-block flex w-full items-center justify-center p-fluid-1 text-page-card-icon"
            >
              <SocialIcon
                kind={socialUrlItem?.key as SocialKeys}
                className="size-fluid-5 shrink-0 transition-all"
              />
            </a>
          ))}
        </div>
      )}

      {(!socialUrls || socialUrls.length === 0) && (
        <div>{t('social_icons_are_missing')}</div>
      )}
    </div>
  )
}
