import { LanguagesContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { BlockCarousel } from '@/components/shared/block-carousel'
import { cn } from '@/lib/utils'

import { BlockProps } from '../shared/block-switch'

export const LanguagesBlock = ({ block, blockMeta, h }: BlockProps) => {
  const content = block?.content?.content?.value as LanguagesContent
  const items = content?.languages || []
  const isShort = h <= 4
  const showTitle = content?.title

  return (
    <div className="flex size-full flex-col rounded-page-widget">
      {showTitle && (
        <div className="p-block">
          <div className="page-heading line-clamp-2 grow text-fluid-lg">
            {content.title}
          </div>
        </div>
      )}

      <BlockCarousel
        count={items.length}
        viewportClassName={cn('px-block rounded-b-page-widget-inner', {
          'rounded-t-page-widget-inner': !showTitle,
        })}
        options={{
          startIndex: (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
        }}
      >
        {items.map((item, index) => {
          return (
            <div
              className={cn('pb-block flex size-full', {
                'pr-fluid-3 max-w-fluid-72': items.length > 1,
              })}
              key={index}
            >
              <div
                className={cn(
                  'rounded-page-widget-block bg-page-card-background shadow-page-card border-page-card shadow-page-card flex w-full flex-col justify-center space-y-fluid-3 p-fluid-3 text-left text-page-card-text',
                  {
                    'justify-end': isShort,
                  },
                )}
              >
                {(isShort || !item?.proficiency) && <div />}

                {item?.language && (
                  <div
                    className={cn('line-clamp-2', {
                      'text-fluid-2xl': h < 6,
                      'text-fluid-3xl': h >= 6,
                    })}
                  >
                    {item.language}
                  </div>
                )}

                {!isShort && item?.proficiency && (
                  <div className="line-clamp-2">{item.proficiency}</div>
                )}
              </div>
            </div>
          )
        })}
      </BlockCarousel>
    </div>
  )
}
