import { QrContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QrHtmlInnerElement } from '@/components/qr/qr-html-inner-element'
import { env } from '@/env'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'

export const QrBlock = ({ block, siteSlug, scaleFactor }: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as QrContent
  const containerRef = useRef<HTMLAnchorElement>(null)
  const [size, setSize] = useState(0)

  const { data, url } = useMemo(() => {
    const data = prependUrl(
      content?.data ||
        `${env.NEXT_PUBLIC_SITE_URL}/${siteSlug}?utm_source=qr-block`,
    )

    const urlObj = new URL(data)
    const url = `${urlObj.hostname}${urlObj.pathname.replace(/\/+$/, '')}`

    return { data, url }
  }, [content?.data, siteSlug])

  useEffect(() => {
    const calculateSize = () => {
      const width = containerRef.current?.offsetWidth ?? 0
      const height = containerRef.current?.offsetHeight ?? 0
      const newSize = Math.min(width, height) - 72 * scaleFactor // Adjust this calculation as needed
      setSize(newSize)
    }

    const resizeObserver = new ResizeObserver(calculateSize)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    // Initial calculation
    calculateSize()

    return () => resizeObserver.disconnect()
  }, [containerRef, scaleFactor])

  return (
    <a
      ref={containerRef}
      className="flex size-full items-center justify-center overflow-hidden rounded-page-widget-inner p-fluid-8"
      href={data}
      target="_blank"
    >
      {content?.title && (
        <div className="px-block page-heading absolute inset-x-0 top-fluid-3 w-full truncate text-center text-fluid-sm">
          {content.title}
        </div>
      )}

      <QrHtmlInnerElement
        theme={{
          id: 'custom',
          key: t('custom'),
          logoColor: 'currentColor',
          foregroundColor: 'currentColor',
          backgroundColor: 'currentColor',
          borderColor: 'currentColor',
        }}
        data={data}
        className="aspect-square w-full"
        style={{
          height: size,
          width: size,
        }}
        correction="H"
      />

      <div className="px-block absolute inset-x-0 bottom-fluid-3 w-full truncate text-center text-fluid-sm">
        {url}
      </div>
    </a>
  )
}
