import { cn } from '@/lib/utils'

import { PageBreakStylesProps } from './page-break-switch'

export const DottedLine = ({ className }: PageBreakStylesProps) => {
  return (
    <div
      className={cn(
        'w-full border-t-4 border-dotted border-page-divider',
        className,
      )}
    />
  )
}
