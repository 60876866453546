import { useEffect } from 'react'

import { getFullHeight } from '@/components/blocks/shared/utils'

type Props = {
  websiteUrl: string
  h: number
  color?: string
  viewportWidth: number
  scaleFactor: number
}

export const CalendlyBlock = ({ websiteUrl, h, scaleFactor }: Props) => {
  const HEIGHT = getFullHeight(h, scaleFactor)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      className="relative"
      style={{ width: '100%', height: HEIGHT, background: '#fff' }}
    >
      <div
        className="calendly-inline-widget"
        data-url={`${websiteUrl}?hide_gdpr_banner=1&_height=${HEIGHT}`}
        style={{ width: '100%', height: HEIGHT }}
      />
    </div>
  )
}
