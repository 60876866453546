import { ServiceItem } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ChevronRightIcon } from '@fingertip/icons'

import { cn } from '@/lib/utils'

type Props = {
  items: ServiceItem[]
  visibleCount: number
}

export const ServicesList = ({ visibleCount, items }: Props) => {
  return (
    <>
      {items.map((item, index) => (
        <div
          key={index}
          className={cn(
            'bg-page-card-background border-page-card rounded-page-widget-block flex flex-row items-center justify-between px-fluid-3 py-fluid-3 mb-fluid-2 text-page-card-text',
            {
              'opacity-0': index >= visibleCount,
            },
          )}
        >
          <span className="truncate text-page-card-text">{item.title}</span>

          {item?.price ? (
            <span className="truncate text-page-card-text">
              {item?.price}
              {item?.unit ? ` per ${item?.unit}` : ''}
            </span>
          ) : (
            <ChevronRightIcon className="size-fluid-4 text-page-secondary-button-text" />
          )}
        </div>
      ))}
    </>
  )
}
