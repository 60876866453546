import { differenceInHours, format } from 'date-fns'
import React from 'react'

import { cn } from '@/lib/utils'

type DateDisplayProps = {
  start: Date
  end: Date
}
export const DateDisplay = ({ start, end }: DateDisplayProps) => {
  const hours = differenceInHours(new Date(end), new Date(start))
  if (hours <= 24) {
    return <DateDisplaySingle date={start} />
  } else {
    return <DateDisplayRange start={start} end={end} />
  }
}

type DateDisplaySingle = {
  date: Date
}

export const DateDisplaySingle = ({ date }: DateDisplaySingle) => {
  // Parse the input date string and format it
  const monthName = format(new Date(date), 'MMM').toUpperCase()
  const dayNumber = format(new Date(date), 'dd')

  return (
    <div className="w-fluid-8 rounded-page-widget-3 shadow-xs">
      <div className="h-fluid-2.5 rounded-t-page-widget-3 bg-white text-center text-fluid-xxs font-bold text-gray-500">
        {monthName}
      </div>
      <div className="flex h-fluid-5 items-start justify-center rounded-b-page-widget-3 bg-red-600 text-center text-fluid-sm font-bold text-white">
        {dayNumber}
      </div>
    </div>
  )
}

type DateDisplayRangeProps = {
  start: Date
  end: Date
}
export const DateDisplayRange = ({ start, end }: DateDisplayRangeProps) => {
  // Parse the input date string and format it
  const startMonthName = format(new Date(start), 'MMM').toUpperCase()
  const startDayNumber = format(new Date(start), 'dd')
  const endMonthName = format(new Date(end), 'MMM').toUpperCase()
  const endDayNumber = format(new Date(end), 'dd')

  const monthDisplay =
    startMonthName === endMonthName
      ? startMonthName
      : startMonthName + '  ' + endMonthName

  const dayDisplay =
    startMonthName === endMonthName && startDayNumber === endDayNumber
      ? startDayNumber
      : startDayNumber + ' - ' + endDayNumber

  return (
    <div
      className={cn('rounded-page-widget-3 shadow-xs', {
        'w-fluid-8': monthDisplay.length <= 3 && dayDisplay.length <= 3,
        'w-fluid-12': monthDisplay.length > 3 || dayDisplay.length > 3,
      })}
    >
      <div className="h-fluid-2.5 rounded-t-page-widget-3 bg-white text-fluid-xs">
        <span className="text-fluid-xxs font-bold">{monthDisplay}</span>
      </div>
      <div className="flex h-fluid-5 items-start justify-center rounded-b-page-widget-3 bg-red-600 text-white">
        <span
          className={cn({
            'text-fluid-sm font-bold': dayDisplay.length <= 3,
            'text-fluid-xs font-bold': dayDisplay.length > 3,
          })}
        >
          {dayDisplay}
        </span>
      </div>
    </div>
  )
}
