/* eslint-disable @next/next/no-img-element */
import { PauseIcon, PlayIcon } from '@fingertip/icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

import { SpotifyIconBox } from './spotify-icon-box'
import { SpotifyPlaybackProps } from './web-playback'

type Props = {
  playlist: any
} & SpotifyPlaybackProps

export const Playlist = ({ playlist, h }: Props) => {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)
  const [activeTrack, setActiveTrack] = useState(0)
  const audioRef = useRef<null | HTMLAudioElement>(null)

  const tracks = useMemo(() => {
    return (playlist?.tracks?.items || []).slice(0, 25)
  }, [playlist?.tracks?.items])

  useEffect(() => {
    audioRef.current = new Audio(tracks?.[0]?.track?.preview_url)
  }, [tracks])

  const playAudio = useCallback(
    (index: any) => {
      if (!audioRef.current) return
      audioRef.current.pause()
      audioRef.current.src = tracks?.[index]?.track?.preview_url
      audioRef.current.play()
      setIsPlaying(true)
      setActiveTrack(index)
    },
    [tracks],
  )

  const pauseAudio = useCallback((index: any) => {
    if (!audioRef.current) return
    audioRef.current.pause()
    setIsPlaying(false)
    setActiveTrack(index)
  }, [])

  useEffect(() => {
    return () => {
      if (!audioRef.current) return
      audioRef.current.pause()
      setIsPlaying(false)
      setActiveTrack(0)
    }
  }, [])

  const isShort = h <= 4

  return (
    <div className="relative flex size-full flex-col overflow-hidden">
      <div className="p-block mb-fluid-3 flex items-center pb-0!">
        {playlist?.images?.[0] && (
          <a
            href={playlist?.external_urls?.spotify}
            target="_blank"
            className="relative block"
          >
            <img
              src={playlist?.images?.[0]?.url}
              alt={`${playlist?.name} Playlist Cover`}
              className="rounded-page-widget-block mr-fluid-6 aspect-square w-fluid-24 min-w-fluid-24 object-cover shadow-sm"
              width={100}
              height={100}
              loading="lazy"
            />

            <SpotifyIconBox />
          </a>
        )}

        <div className="w-[calc(100%-124px)]">
          <div>
            <a
              href={playlist?.external_urls?.spotify}
              target="_blank"
              className="page-heading line-clamp-1 text-fluid-lg hover:underline"
            >
              {playlist?.name}
            </a>
            <p className="mb-fluid-2">
              {playlist?.tracks?.items && playlist?.tracks?.items.length} tracks
            </p>
          </div>

          <Button
            onClick={() =>
              isPlaying ? pauseAudio(activeTrack) : playAudio(activeTrack)
            }
            size="blockSm"
            variant="blockPrimary"
            className="min-w-fluid-24"
          >
            {isPlaying ? (
              <PauseIcon className="mr-fluid-2 size-fluid-4" />
            ) : (
              <PlayIcon className="mr-fluid-2 size-fluid-4" />
            )}
            {isPlaying ? t('pause') : t('play')}
          </Button>
        </div>
      </div>

      {!isShort && (
        <div className="p-block overflow-y-auto rounded-b-page-widget pb-0!">
          {tracks.map((item: any, index: number) => (
            <button
              type="button"
              key={index}
              className="group/playlist mb-fluid-2 flex w-full cursor-pointer flex-row items-center justify-between py-fluid-1 text-left"
              onClick={() =>
                isPlaying && activeTrack === index
                  ? pauseAudio(index)
                  : playAudio(index)
              }
            >
              <div className="flex items-center">
                {isPlaying && activeTrack === index ? (
                  <PauseIcon className="my-fluid-2 ml-fluid-2 mr-fluid-4 size-fluid-4" />
                ) : (
                  <>
                    <div className="my-fluid-2 ml-fluid-2 mr-fluid-4 hidden group-hover/playlist:block">
                      <PlayIcon className="size-fluid-4" />
                    </div>

                    <img
                      src={item?.track?.album?.images?.[0]?.url}
                      alt={`${item?.track?.name} cover`}
                      className="mr-fluid-2 aspect-square w-fluid-8 min-w-fluid-8 rounded-lg object-cover group-hover/playlist:hidden"
                      width={32}
                      height={32}
                      loading="lazy"
                    />
                  </>
                )}

                <div>
                  <div className="mb-fluid-0.5 line-clamp-1">
                    {item?.track?.name}
                  </div>
                  <div className="line-clamp-1 text-fluid-xs opacity-70">
                    {(item?.track?.artists || [])
                      .map((artist: any) => artist?.name)
                      .join(', ')}
                  </div>
                </div>
              </div>

              <div className="ml-fluid-2 shrink-0 text-nowrap text-right opacity-70">
                {formatDuration(item?.track?.duration_ms)}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

const formatDuration = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 60000)
  const seconds = Math.round((milliseconds % 60000) / 1000)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}
