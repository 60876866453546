import { LinkListItem } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowUpRightIcon } from '@fingertip/icons'

import { cn } from '@/lib/utils'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'

type Props = {
  items: LinkListItem[]
  visibleCount: number | null
} & Pick<BlockProps, 'handleClick' | 'block' | 'domain' | 'siteSlug'>

export const LinkList = ({
  siteSlug,
  domain,
  visibleCount,
  items,
  handleClick,
  block,
}: Props) => {
  return (
    <>
      {items.map((item, index) => (
        <a
          key={index}
          onClick={() => handleClick?.(block, item.title, item?.websiteUrl)}
          href={prependUrl(item?.websiteUrl, { siteSlug, domain })}
          target={!!item?.websiteUrl ? '_blank' : undefined}
          className={cn(
            'bg-page-card-background border-page-card rounded-page-widget-block flex flex-row items-center justify-between p-fluid-2 mb-fluid-2 text-page-card-text min-h-fluid-12',
            {
              'opacity-0 pointer-events-none':
                visibleCount !== null ? index >= visibleCount : false,
            },
          )}
        >
          {item?.media?.type ? (
            <MediaImage
              media={item?.media}
              className="mr-fluid-2 size-fluid-8! min-w-fluid-8 rounded-page-widget-5"
              width={32}
              height={32}
              style={{
                overflow: 'hidden',
              }}
            />
          ) : (
            <div className="mr-fluid-2 size-fluid-8 shrink-0" />
          )}

          <div
            className="flex-1 truncate text-center text-page-card-text"
            title={item?.title}
          >
            {item?.title}
          </div>

          <div className="flex w-fluid-8 shrink-0 justify-center text-page-card-text">
            <ArrowUpRightIcon className="size-fluid-4" />
          </div>
        </a>
      ))}
    </>
  )
}
