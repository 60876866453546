import { WebsiteEmbedContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'

import { AbsoluteTitle } from '../../shared/absolute-title'
import { BlockProps } from '../shared/block-switch'

export const WebsiteEmbedBlock = ({ block, w, h, context }: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as WebsiteEmbedContent
  const [open, setOpen] = useState(false)

  const showFallback = context === 'SEARCH'

  if (!content?.websiteUrl) {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('no_website_url_provided')}</p>
      </div>
    )
  }

  const html = `<style>html,body{height:calc(100% - 2px);}body{margin:0;}</style><iframe src='${content?.websiteUrl}' width='100%' height='100%'></iframe>`

  if (content?.isModal) {
    return (
      <ResponsiveSheet open={open} onOpenChange={setOpen}>
        <DialogTrigger className="cursor-pointer flex size-full items-center justify-center outline-hidden!">
          <span className="page-heading text-fluid-lg">
            {content?.title || t('view_embed')}
          </span>
        </DialogTrigger>

        <ResponsiveSheetContent className="flex max-h-[calc(100vh-100px)] w-full min-w-full flex-col overflow-y-auto rounded-none! p-0">
          <VisuallyHidden>
            <DialogTitle>Website embed</DialogTitle>
          </VisuallyHidden>

          <iframe srcDoc={html} width="100%" height="100%" />
        </ResponsiveSheetContent>
      </ResponsiveSheet>
    )
  }

  return (
    <div className="relative size-full overflow-hidden rounded-page-widget-inner">
      {!showFallback && (
        <>
          <iframe srcDoc={html} width="100%" height="100%" />

          {content?.title && (
            <AbsoluteTitle title={content.title} h={h} w={w} />
          )}
        </>
      )}

      {showFallback && (
        <div className="flex h-full items-center justify-center text-center">
          {t('website_link')}
        </div>
      )}
    </div>
  )
}
