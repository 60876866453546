import {
  ArrowRightIcon,
  ArrowUpRightIcon,
  GoogleColoredIcon,
  LogoStandaloneIcon,
  StarFilledIcon,
} from '@fingertip/icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { RatingsWide } from '@/components/blocks/ratings/ratings-wide'
import { ButtonDiv } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { pluralize } from '@/lib/utils/utils'

type Props = {
  source: 'GOOGLE' | 'FINGERTIP' | 'CUSTOM'
  h: number
  w: number
  ratingsAverage: number
  ratingCount: number
}

export const WithoutReviews = ({
  source,
  ratingsAverage,
  ratingCount,
  h,
  w,
}: Props) => {
  const { t } = useTranslation()
  const displayAverage = useMemo(
    () => (Math.round(ratingsAverage * 100) / 100).toFixed(1),
    [ratingsAverage],
  )

  return (
    <div
      className={cn('flex size-full', {
        'flex-col justify-between': h >= 4 && w < 3,
        'flex-row justify-between items-center': h < 4 || (h >= 4 && w >= 3),
      })}
    >
      <div>
        <div className="flex flex-row items-center justify-start gap-fluid-2 text-page-heading-text">
          <span className="page-heading text-fluid-3xl leading-none!">
            {displayAverage}
          </span>

          {w < 4 ? (
            <StarFilledIcon className="size-fluid-6" />
          ) : (
            <RatingsWide className="size-fluid-6" rating={ratingsAverage} />
          )}
        </div>

        {h >= 4 && (
          <div className="mt-fluid-1 text-fluid-sm">
            {ratingCount > 0 && (
              <>
                +{ratingCount.toLocaleString('en-US')}{' '}
                {pluralize(ratingCount, 'review')}
              </>
            )}

            {ratingCount === 0 && <>{t('no_reviews_yet')}</>}
          </div>
        )}
      </div>

      <div className="flex flex-row items-center space-x-fluid-2">
        <ButtonDiv variant="blockSecondary" size="blockSm">
          {source === 'FINGERTIP' && (
            <LogoStandaloneIcon className="size-fluid-4 text-current" />
          )}

          {source === 'GOOGLE' && (
            <GoogleColoredIcon className="size-fluid-4" />
          )}

          {(h >= 4 || w >= 3) && (
            <div className="ml-fluid-2">{t('leave_a_review')}</div>
          )}

          {source === 'FINGERTIP' ? (
            <ArrowRightIcon className="ml-fluid-2 size-fluid-4" />
          ) : (
            <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4" />
          )}
        </ButtonDiv>
      </div>
    </div>
  )
}
