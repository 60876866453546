import { ServicesContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useState } from 'react'

import {
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { cn } from '@/lib/utils/cn'

import { BlockProps } from '../shared/block-switch'
import { ServicesInner } from './services-inner'

export const ServicesBlock = ({
  block,
  h,
  w,
  viewportWidth,
  scaleFactor,
}: BlockProps) => {
  const [open, setOpen] = useState(false)
  const content = block?.content?.content?.value as ServicesContent

  return (
    <ResponsiveSheet open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className={cn(
          'cursor-pointer outline-hidden! flex size-full flex-col p-block',
          {
            'justify-center h-full': h <= 2,
          },
        )}
      >
        <ServicesInner
          content={content}
          h={h}
          w={w}
          viewportWidth={viewportWidth}
          scaleFactor={scaleFactor}
        />
      </DialogTrigger>

      <ResponsiveSheetContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
        {content?.title && (
          <DialogHeader className="w-full pb-fluid-2 pt-fluid-4">
            <DialogTitle className="text-center">{content.title}</DialogTitle>
          </DialogHeader>
        )}
        <div className="flex-1">
          <div className="space-y-fluid-1">
            {(content?.services || []).map((item, index) => (
              <div
                key={index}
                className="flex flex-col space-y-fluid-0.5 border-b border-border py-fluid-2"
              >
                <div className="w-full font-bold leading-tight">
                  {item?.title}
                </div>

                {item?.price && (
                  <div className="w-full leading-[1.5]">
                    {item.price}
                    {item?.unit ? ` per ${item?.unit}` : ''}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </ResponsiveSheetContent>
    </ResponsiveSheet>
  )
}
