import { useMutation } from '@connectrpc/connect-query'
import { PollMeta } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import {
  createPollResponse,
  deletePollResponse,
} from '@fingertip/creator-proto/gen/fingertip/creator/poll_response/v1/poll_response-PollResponseService_connectquery'
import { BulletListIcon } from '@fingertip/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { v4 } from 'uuid'

import { IconBox } from '@/components/shared/icon-box'
import { Button } from '@/components/ui/button'
import { useToken } from '@/lib/hooks/use-token'
import { cn } from '@/lib/utils/cn'

import { BlockProps } from '../shared/block-switch'

export const PollBlock = ({ block, blockMeta, h }: BlockProps) => {
  const { t } = useTranslation()

  const { callOptions } = useToken()
  const [selectedOptionId, setSelectedOptionId] = useState<
    string | undefined | null
  >()

  const [responseId] = useState(v4())
  const meta = blockMeta?.meta?.metaContent?.value as PollMeta

  const mutationCreate = useMutation(createPollResponse, {
    callOptions,
    onSuccess: () => {
      toast.success(t('successfully_submitted'))
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const mutationDelete = useMutation(deletePollResponse, {
    callOptions,
    onSuccess: () => {
      toast.success(t('successfully_removed_response'))
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const handleSelect = useCallback(
    (optionId: string) => {
      if (selectedOptionId === optionId && mutationCreate.isSuccess) {
        setSelectedOptionId(null)
        mutationDelete.mutate({
          pollResponseId: responseId,
        })
        return
      }

      setSelectedOptionId(optionId)

      mutationCreate.mutate({
        responseId,
        pollOptionId: optionId,
        source: `blockId:${block.id}`,
      })
    },
    [block.id, mutationCreate, mutationDelete, responseId, selectedOptionId],
  )

  const totalCount =
    (meta?.poll?.totalResponseCount || 0) + (selectedOptionId ? 1 : 0)

  return (
    <div className="p-block relative flex size-full flex-col overflow-y-auto">
      {h >= 6 && (
        <IconBox className="mb-fluid-3">
          <BulletListIcon className="size-fluid-4" />
        </IconBox>
      )}

      {meta?.poll?.title && (
        <div className="mb-fluid-3 shrink-0">
          <div className="page-heading line-clamp-2 grow text-fluid-lg">
            {meta?.poll?.title}
          </div>
        </div>
      )}

      <div className="flex w-full flex-1 flex-col flex-wrap justify-center space-y-fluid-2">
        {(meta?.pollOptions || []).map((option, index) => {
          const count =
            (option?.responseCount || 0) +
            (selectedOptionId === option.id ? 1 : 0)
          const percentage = Math.round((count / totalCount) * 100)
          const active = !!selectedOptionId && selectedOptionId === option.id

          return (
            <Button
              variant={active ? 'blockPrimary' : 'blockSecondary'}
              key={index}
              className="flex justify-between"
              size={h > 6 ? 'block' : 'blockSm'}
              onClick={() => handleSelect(option.id)}
            >
              <div className={cn({ 'text-center w-full': !selectedOptionId })}>
                {option.text}
              </div>
              {selectedOptionId && <div>{percentage}%</div>}
            </Button>
          )
        })}
      </div>
    </div>
  )
}
