import { disableQuery, useQuery } from '@connectrpc/connect-query'
import { ContactContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { getUserProfile } from '@fingertip/creator-proto/gen/fingertip/creator/user_profile/v1/user_profile-UserProfileService_connectquery'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import { CreateFingertipPage } from '@/components/blocks/contact/contact-dialog/create-fingertip-page'
import { SaveContactQr } from '@/components/blocks/contact/contact-dialog/save-contact-qr'
import { ShareContactDetails } from '@/components/blocks/contact/contact-dialog/share-contact-details'
import { DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { useToken } from '@/lib/hooks/use-token'
import { RootStoreContext } from '@/lib/stores/root-store'

import { BlockProps } from '../shared/block-switch'

type Props = {
  open: boolean
  onOpenChange: (open: boolean) => void
} & Pick<BlockProps, 'block' | 'handleClick' | 'siteSlug'>

export const ContactDialog = observer(
  ({ block, handleClick, open, onOpenChange, siteSlug }: Props) => {
    const {
      publicStore: { contactDialogStage, setContactDialogStage },
    } = useContext(RootStoreContext)
    const { callOptions, token } = useToken()
    const { data: userData } = useQuery(
      getUserProfile,
      !token ? disableQuery : {},
      {
        callOptions,
      },
    )
    const defaultsFromUserProfile = useMemo(() => {
      return {
        email: userData?.userProfile?.email || '',
        name: userData?.userProfile?.name || '',
        phone: userData?.userProfile?.phone || '',
      }
    }, [userData?.userProfile])

    const content = block?.content?.content?.value as ContactContent
    // save = save contact, send = send details, create = create account
    const [userDetails, setUserDetails] = useState<{
      email?: string
      name?: string
      phone?: string
    } | null>(null)

    const onOpenChangeOverride = useCallback(
      (open: boolean) => {
        onOpenChange(open)
        if (!open) {
          setContactDialogStage('SAVE')
        }
      },
      [onOpenChange, setContactDialogStage],
    )

    const handleNext = useCallback(
      (
        newUserDetails?: {
          email?: string
          name?: string
          phone?: string
        } | null,
      ) => {
        if (contactDialogStage === 'SAVE') {
          setContactDialogStage('SEND')
        } else if (contactDialogStage === 'SEND' && !token && newUserDetails) {
          // Details will only be forwarded if it is not an existing user
          setUserDetails(newUserDetails)
          setContactDialogStage('CREATE')
        } else {
          onOpenChangeOverride(false)
        }
      },
      [contactDialogStage, onOpenChangeOverride, setContactDialogStage, token],
    )

    return (
      <ResponsiveSheet open={open} onOpenChange={onOpenChangeOverride}>
        <ResponsiveSheetContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
          <VisuallyHidden>
            <DialogTitle>Contact</DialogTitle>
          </VisuallyHidden>

          {contactDialogStage === 'SAVE' && (
            <SaveContactQr
              content={content}
              handleClick={handleClick}
              block={block}
              next={handleNext}
              siteSlug={siteSlug}
              context="SITE_OWNER"
            />
          )}
          {contactDialogStage === 'SEND' && (
            <ShareContactDetails
              siteSlug={siteSlug}
              content={content}
              next={handleNext}
              userDetails={defaultsFromUserProfile}
            />
          )}
          {contactDialogStage === 'CREATE' && (
            <CreateFingertipPage
              siteSlug={siteSlug}
              returnTo="/onboarding"
              next={handleNext}
              userDetails={userDetails}
            />
          )}
        </ResponsiveSheetContent>
      </ResponsiveSheet>
    )
  },
)
