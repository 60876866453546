/* eslint-disable @next/next/no-img-element */
import { PauseIcon, PlayIcon } from '@fingertip/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

import { SpotifyIconBox } from './spotify-icon-box'
import { SpotifyPlaybackProps } from './web-playback'

type Props = {
  track: any
} & SpotifyPlaybackProps

export const Track = ({ track }: Props) => {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef<null | HTMLAudioElement>(null)

  // Update audio source when the track changes
  useEffect(() => {
    audioRef.current = new Audio(track?.preview_url)
  }, [track])

  // Play audio
  const playAudio = useCallback(() => {
    audioRef?.current?.play()
    setIsPlaying(true)
  }, [])

  // Pause audio
  const pauseAudio = useCallback(() => {
    audioRef?.current?.pause()
    setIsPlaying(false)
  }, [])

  // Cleanup audio on unmount
  useEffect(() => {
    return () => {
      audioRef?.current?.pause()
      setIsPlaying(false)
    }
  }, [])

  return (
    <div className="p-block flex size-full items-center">
      {track?.album?.images?.[0]?.url && (
        <div className="flex items-center justify-center">
          <a
            href={track?.external_urls?.spotify}
            target="_blank"
            className="relative block"
          >
            <img
              src={track?.album?.images?.[0]?.url}
              alt={`${track?.name} Album Cover`}
              className="rounded-page-widget-block mr-fluid-6 aspect-square w-fluid-24 min-w-fluid-24 object-cover shadow-sm"
              width={100}
              height={100}
              loading="lazy"
            />

            <SpotifyIconBox />
          </a>
        </div>
      )}

      <div className="flex flex-1 flex-col justify-between">
        <div>
          <a
            href={track?.external_urls?.spotify}
            target="_blank"
            className="page-heading line-clamp-1 text-fluid-lg hover:underline"
          >
            {track?.name}
          </a>
          <a
            href={track?.artists?.[0]?.external_urls?.spotify}
            className="mb-fluid-2 line-clamp-1 hover:underline"
            target="_blank"
          >
            {(track?.artists || [])
              .map((artist: any) => artist?.name)
              .join(', ')}
          </a>
        </div>

        {track?.preview_url && (
          <div>
            <Button
              onClick={isPlaying ? pauseAudio : playAudio}
              size="blockSm"
              variant="blockPrimary"
              className="min-w-fluid-24"
            >
              {isPlaying ? (
                <PauseIcon className="mr-fluid-2 size-fluid-4" />
              ) : (
                <PlayIcon className="mr-fluid-2 size-fluid-4" />
              )}
              {isPlaying ? t('pause') : t('play')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
