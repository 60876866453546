import { useMutation } from '@connectrpc/connect-query'
import { SiteContactMarketingStatus } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/site_contact_marketing_status_pb'
import { SubscribeContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { createSiteContact } from '@fingertip/creator-proto/gen/fingertip/creator/site_contact/v1/site_contact-SiteContactService_connectquery'
import { ArrowRightIcon, Email2Icon } from '@fingertip/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'sonner'
import { z } from 'zod'

import { getFullHeight } from '@/components/blocks/shared/utils'
import { InputField } from '@/components/fields/input-field'
import { PhoneField } from '@/components/fields/phone-field'
import { IconBox } from '@/components/shared/icon-box'
import { Button, ButtonDiv, ButtonProps } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useToken } from '@/lib/hooks/use-token'
import { clientGetUrl } from '@/lib/utils/client-get-url'
import { cn } from '@/lib/utils/cn'

import { BlockProps } from '../shared/block-switch'

const schema = z.object({
  email: z.string().email('Invalid email address'),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z
    .string()
    .refine(isValidPhoneNumber, { message: 'Invalid phone number' })
    .or(z.literal('')),
})

export type Schema = z.infer<typeof schema>

const SubscribeForm = ({
  content,
  inputClassName,
  siteSlug,
  h,
  buttonVariant = 'blockPrimary',
  phoneInputClassName,
}: {
  content: SubscribeContent
  w: number
  h: number
  inputClassName?: string
  buttonVariant?: ButtonProps['variant']
  siteSlug: string
  phoneInputClassName?: string
}) => {
  const { t } = useTranslation()
  const { callOptions } = useToken()
  const [submitted, setSubmitted] = useState(false)
  const isShort = h <= 4

  const mutation = useMutation(createSiteContact, {
    callOptions,
    onSuccess: () => {
      toast.success(t('successfully_subscribed'))
      setSubmitted(true)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const { control, handleSubmit } = useForm<Schema>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
  })

  const submitHandler = useCallback(
    async (input: Schema) => {
      mutation.mutate({
        siteSlug,
        source: 'SUBSCRIBED_TO_NEWSLETTER',
        marketingStatus: SiteContactMarketingStatus.SUBSCRIBED,
        ...input,
      })
    },
    [mutation, siteSlug],
  )

  if (submitted) {
    return (
      <div className="flex size-full items-center justify-center">
        <h3 className="text-center text-fluid-lg">
          {t('thank_you_for_your_submission')}
        </h3>
      </div>
    )
  }

  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      className="flex h-full flex-col justify-between"
    >
      <div className="flex h-full flex-1 flex-col justify-center gap-fluid-2 py-fluid-2">
        {content?.collectFullName && (
          <div className="flex w-full flex-row justify-center space-x-fluid-2">
            <InputField
              control={control}
              type="name"
              name="firstName"
              placeholder={t('first_name')}
              className="mb-0 w-full"
              inputClassName={inputClassName}
            />

            <InputField
              control={control}
              type="name"
              name="lastName"
              placeholder={t('last_name')}
              className="mb-0 w-full"
              inputClassName={inputClassName}
            />
          </div>
        )}

        <InputField
          control={control}
          type="email"
          name="email"
          placeholder={t('email')}
          className="mb-0 w-full"
          inputClassName={inputClassName}
        />

        {content?.collectPhoneNumber && (
          <PhoneField
            control={control}
            name="phone"
            placeholder={t('phone_number')}
            className={cn('mb-0 w-full', phoneInputClassName)}
            inputClassName={inputClassName}
          />
        )}

        {!content.hideConditions && (
          <p className="mt-fluid-1 text-center text-fluid-xs opacity-70">
            {t('by_subscribing_you_a')}{' '}
            <a
              href={`${clientGetUrl()}/privacy`}
              target="_blank"
              className="underline"
            >
              {t('privacy_policy')}
            </a>
            .
          </p>
        )}
      </div>

      <Button
        size={isShort ? 'blockSm' : 'block'}
        variant={buttonVariant}
        className={cn({
          'w-full': !isShort,
        })}
      >
        {content?.buttonText || t('subscribe')}
        <ArrowRightIcon className="ml-fluid-2 size-fluid-4" />
      </Button>
    </form>
  )
}

export const SubscribeBlock = ({
  block,
  h,
  w,
  siteSlug,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as SubscribeContent

  const [open, setOpen] = useState(false)

  const HEIGHT = getFullHeight(h, scaleFactor)

  const isNarrow = w <= 2
  const isShort = h <= 4
  let usePrompt = true

  if (!isNarrow) {
    let totalHeight = 0

    // Base height
    totalHeight += 253

    // Add height for each field
    if (content?.collectFullName) totalHeight += 60
    if (content?.collectPhoneNumber) totalHeight += 60
    if (!content?.hideConditions) totalHeight += 40

    // Check if total height exceeds available height
    if (totalHeight * scaleFactor <= HEIGHT) {
      usePrompt = false
    }
  }

  if (usePrompt) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          className={cn('cursor-pointer outline-hidden! p-block', {
            'flex size-full items-center': h <= 2,
            'flex w-full h-full flex-col': h > 2,
          })}
        >
          <div className="w-full flex-1">
            {h > 5 && (
              <IconBox className="mb-fluid-3">
                <Email2Icon className="size-fluid-4" />
              </IconBox>
            )}

            {content?.title && (
              <div className="mb-fluid-1">
                <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
                  {content.title}
                </div>
              </div>
            )}

            {!isShort && (
              <div className="line-clamp-2 text-left">
                {content.description}
              </div>
            )}
          </div>

          <ButtonDiv
            size={isShort ? 'blockSm' : 'block'}
            variant="blockPrimary"
            className={cn({
              'w-full': !isShort,
            })}
          >
            {h > 2 && (
              <span className="mr-fluid-2">
                {content?.buttonText || t('subscribe')}
              </span>
            )}
            <ArrowRightIcon className="size-fluid-4" />
          </ButtonDiv>
        </DialogTrigger>

        <DialogContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
          {content?.title ? (
            <DialogHeader>
              <DialogTitle>{content.title}</DialogTitle>
            </DialogHeader>
          ) : (
            <VisuallyHidden>
              <DialogTitle>{t('subscribe')}</DialogTitle>
            </VisuallyHidden>
          )}

          {content?.description && <div>{content.description}</div>}

          <SubscribeForm
            w={w}
            h={h}
            content={content}
            siteSlug={siteSlug}
            buttonVariant="default"
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div className="p-block flex size-full flex-col">
      {h > 7 && (
        <IconBox className="mb-fluid-3">
          <Email2Icon className="size-fluid-4" />
        </IconBox>
      )}

      {content?.title && (
        <div className="mb-fluid-1 shrink-0">
          <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
            {content.title}
          </div>
        </div>
      )}

      {!isShort && (
        <div className="line-clamp-2 shrink-0 text-left">
          {content.description}
        </div>
      )}

      <SubscribeForm
        w={w}
        h={h}
        content={content}
        inputClassName="bg-page-input-background text-page-input-text rounded-page-widget-block border-page-input-border shadow-page-input placeholder:text-page-input-placeholder! h-fluid-12! text-fluid-base"
        phoneInputClassName="fluid-phone-input"
        siteSlug={siteSlug}
      />
    </div>
  )
}
