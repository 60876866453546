import { cn } from '@/lib/utils'

import { PageBreakStylesProps } from './page-break-switch'

export const ThickLine = ({ className }: PageBreakStylesProps) => {
  return (
    <div
      className={cn(
        'h-fluid-1 w-full border-t-4 border-page-divider',
        className,
      )}
    />
  )
}
