import { VideoContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { VideoSwitch } from '@/components/video/video-switch'

import { BlockProps } from '../shared/block-switch'

export const VideoBlock = ({ block }: BlockProps) => {
  const content = block?.content?.content?.value as VideoContent

  return <VideoSwitch video={content?.video} isModal={content?.isModal} />
}
