import { GoogleRatingStar } from '@/components/blocks/ratings/google-rating-star'
import { cn } from '@/lib/utils/cn'

type Props = {
  rating?: number | null
  className?: string
  containerClassName?: string
}

export const RatingsWide = ({
  rating,
  className,
  containerClassName,
}: Props) => {
  return (
    <div className={cn('flex flex-row', containerClassName)}>
      {[...Array(5)].map((_, index) => {
        const count = index + 1
        const fullStar = rating && rating >= count
        const halfStar = rating && rating < count && count - rating < 1

        return (
          <GoogleRatingStar
            key={index}
            fill={fullStar ? 'full' : halfStar ? 'half' : 'empty'}
            className={className}
          />
        )
      })}
    </div>
  )
}
