import { LinkListContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowUpRightIcon } from '@fingertip/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { cn } from '@/lib/utils/cn'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'
import { LinkListInner } from './link-list-inner'

export const LinkListBlock = ({
  siteSlug,
  domain,
  block,
  handleClick,
  w,
  h,
  viewportWidth,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const content = block?.content?.content?.value as LinkListContent

  return (
    <ResponsiveSheet open={open} onOpenChange={setOpen}>
      <div
        className={cn('flex size-full flex-col p-block', {
          'justify-center h-full': h <= 2,
        })}
      >
        <LinkListInner
          content={content}
          w={w}
          h={h}
          handleClick={handleClick}
          block={block}
          handleOpen={() => setOpen(true)}
          viewportWidth={viewportWidth}
          scaleFactor={scaleFactor}
          siteSlug={siteSlug}
          domain={domain}
        />
      </div>

      <ResponsiveSheetContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
        <DialogHeader className="w-full pb-fluid-2 pt-fluid-4">
          <DialogTitle className="text-center">{t('links')}</DialogTitle>
        </DialogHeader>

        <div className="w-full flex-1">
          <div className="flex w-full flex-col space-y-fluid-2">
            {(content?.links || []).map((item, index) => (
              <a
                key={index}
                onClick={() => handleClick?.(block, item.title)}
                href={prependUrl(item?.websiteUrl, { siteSlug, domain })}
                target={!!item?.websiteUrl ? '_blank' : undefined}
                className="flex min-h-12 w-full flex-row items-center justify-between rounded-2xl bg-muted p-fluid-2"
              >
                {item?.media?.type ? (
                  <MediaImage
                    media={item?.media}
                    className="mr-fluid-2 size-fluid-8! min-w-fluid-8 rounded-lg"
                    width={32}
                    height={32}
                    style={{
                      overflow: 'hidden',
                    }}
                  />
                ) : (
                  <div className="mr-fluid-2 w-fluid-8 shrink-0" />
                )}

                <div className="flex-1 text-center" title={item?.title}>
                  {item?.title}
                </div>

                <div className="flex w-fluid-8 shrink-0 justify-center">
                  <ArrowUpRightIcon className="size-fluid-4" />
                </div>
              </a>
            ))}
          </div>
        </div>
      </ResponsiveSheetContent>
    </ResponsiveSheet>
  )
}
