import { FileContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import {
  ArrowWallDownIcon,
  CloudSimpleDownloadIcon,
  StarIcon,
} from '@fingertip/icons'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconBox } from '@/components/shared/icon-box'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { fileSrc } from '@/lib/utils/image-loader'

import { BlockAssetInner } from '../shared/block-asset-inner'
import { BlockProps } from '../shared/block-switch'
import { EmailForm } from './email-form'

export const FileBlock = ({
  block,
  handleClick,
  w,
  h,
  y,
  siteSlug,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as FileContent
  const [downloaded, setDownloaded] = useState(false)
  const [open, setOpen] = useState(false)

  const handleDownload = useCallback(async () => {
    handleClick?.(block)
    setDownloaded(true)
    setOpen(false)

    let blob = await fetch(
      fileSrc({
        bucket: content?.fileAssetBucket,
        src: content?.fileAssetSrc,
      }),
    ).then((r) => r.blob())
    const href = URL.createObjectURL(blob)

    // Create a link element to trigger the download
    const link = document.createElement('a')
    link.href = href
    link.download = `${content?.fileAssetSrc}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [block, content?.fileAssetBucket, content?.fileAssetSrc, handleClick])

  if (downloaded) {
    return (
      <div className="p-block flex size-full flex-col items-center gap-fluid-2">
        <IconBox className="mb-fluid-3">
          <StarIcon className="size-fluid-4" />
        </IconBox>

        <div className="page-heading flex flex-col gap-1 text-center text-fluid-lg">
          <span>{t('youre_all_set')}</span>
          <span>{t('your_download_is_ready')}</span>
        </div>
      </div>
    )
  }

  if (content?.collectEmail) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger className="cursor-pointer relative flex size-full text-left outline-hidden!">
          <BlockAssetInner
            media={content?.media}
            title={content?.title}
            description={content?.description}
            buttonText={content?.buttonText || t('download')}
            icon={<CloudSimpleDownloadIcon className="size-fluid-4" />}
            w={w}
            h={h}
            y={y}
            buttonIcon={
              <ArrowWallDownIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
            }
            scaleFactor={scaleFactor}
          />
        </DialogTrigger>

        <DialogContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{t('get_your_file_instantly')}</DialogTitle>
          </DialogHeader>

          <div>
            {t(
              'simply_enter_your_email_address_and_well_download_the_file_right_away',
            )}
          </div>

          <EmailForm
            w={w}
            h={h}
            siteSlug={siteSlug}
            buttonVariant="default"
            onSuccess={handleDownload}
            fileContent={content}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <button
      type="button"
      className="block size-full cursor-pointer text-left"
      onClick={handleDownload}
    >
      <BlockAssetInner
        media={content?.media}
        title={content?.title}
        description={content?.description}
        buttonText={content?.buttonText || t('download_0')}
        icon={<CloudSimpleDownloadIcon className="size-fluid-4" />}
        w={w}
        h={h}
        y={y}
        buttonIcon={
          <ArrowWallDownIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
        }
        scaleFactor={scaleFactor}
      />
    </button>
  )
}
