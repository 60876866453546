import {
  SpotifyContent,
  SpotifyMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useTranslation } from 'react-i18next'

import { WebPlayback } from '@/components/spotify/web-playback'

import { BlockProps } from '../shared/block-switch'

export const SpotifyBlock = ({ block, blockMeta, h, w }: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as SpotifyContent
  const meta = blockMeta?.meta?.metaContent?.value as SpotifyMeta

  let url: undefined | URL

  if (!content?.websiteUrl) {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('no_spotify_url_has_been_added')}</p>
      </div>
    )
  }

  try {
    url = new URL(content?.websiteUrl)
  } catch (_) {}

  if (!url) {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('spotify_url_is_invalid')}</p>
      </div>
    )
  }

  if (url.hostname === 'spotify.link') {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('this_spotify_link_format_is_not_supported')}</p>
      </div>
    )
  }

  if (meta) {
    const data = meta?.data ? JSON.parse(meta.data) : undefined

    if (data) {
      return <WebPlayback data={data} w={w} h={h} />
    }
  }

  return (
    <div className="rounded-page-widget-block flex size-full items-center justify-center overflow-hidden bg-[#282828]">
      <iframe
        src={`https://open.spotify.com/embed${url.pathname}?theme=0`}
        width="100%"
        height="100%"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    </div>
  )
}
