import { ContactContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowWallDownIcon, Email2Icon, PhoneIcon } from '@fingertip/icons'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Button, ButtonDiv } from '@/components/ui/button'
import { cn } from '@/lib/utils/cn'
import { convertToTelFormat } from '@/lib/utils/convert-tel-utils'
import { getInitials } from '@/lib/utils/string-utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'

type Props = {
  setOpen: (open: boolean) => void
} & Pick<BlockProps, 'block' | 'handleClick' | 'w' | 'h'>

export const ContactInner = ({ block, handleClick, w, h, setOpen }: Props) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as ContactContent

  const phoneNumber = content?.items?.filter(
    (item) => item.key === 'phone',
  )?.[0]?.value

  const emailAddress = content?.items?.filter(
    (item) => item.key === 'email',
  )?.[0]?.value

  const phoneHref = useMemo(
    () => `tel:${convertToTelFormat(phoneNumber)}`,
    [phoneNumber],
  )
  const emailHref = `mailto:${emailAddress}`

  return (
    <div className="p-block flex size-full flex-col overflow-hidden rounded-page-widget-inner text-page-heading-text">
      {/* Name and Organization */}
      <div
        className={cn('mb-fluid-2 flex flex-1', {
          'space-x-fluid-4 items-center': h <= 5,
          'mb-fluid-3 bg-page-card-background shadow-page-card flex-col justify-between rounded-page-widget-block p-fluid-3 text-page-card-text':
            h > 5,
        })}
      >
        {(h > 5 || w >= 4) && (
          <>
            {content?.media?.type ? (
              <MediaImage
                media={content?.media}
                className="size-fluid-12! min-w-fluid-12"
                width={260}
                height={260}
                style={{
                  borderRadius: 9999,
                  overflow: 'hidden',
                }}
              />
            ) : (
              <Avatar className="size-fluid-12 min-w-fluid-12">
                <AvatarFallback
                  value={getInitials(content?.fullName)}
                  noColor
                />
              </Avatar>
            )}
          </>
        )}

        <div>
          <div className="page-heading line-clamp-2 text-fluid-lg">
            {t('save_contact_details')}
          </div>

          {content?.fullName && (
            <div className="mt-fluid-1">
              <div className="line-clamp-2 text-fluid-sm">
                {content.fullName}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Actions */}
      <div className="flex w-full flex-row space-x-fluid-2">
        {phoneNumber && (
          <a
            href={phoneHref}
            onClick={() => handleClick?.(block, 'Phone on contact')}
            className="w-full"
          >
            <ButtonDiv
              variant="blockSecondary"
              title={t('call')}
              size={w >= 4 && h > 4 ? 'block' : 'blockSm'}
              className="w-full px-fluid-1!"
            >
              <PhoneIcon className="size-fluid-4 shrink-0" />
              {w >= 4 && <span className="ml-fluid-2">{t('call')}</span>}
            </ButtonDiv>
          </a>
        )}

        {emailAddress && (
          <a
            href={emailHref}
            onClick={() => handleClick?.(block, 'Email on contact')}
            className="w-full"
          >
            <ButtonDiv
              variant="blockSecondary"
              title={t('email')}
              size={w >= 4 && h > 4 ? 'block' : 'blockSm'}
              className="w-full px-fluid-1!"
            >
              <Email2Icon className="size-fluid-4 shrink-0" />
              {w >= 4 && <span className="ml-fluid-2">{t('email')}</span>}
            </ButtonDiv>
          </a>
        )}

        <span className="w-full">
          <Button
            onClick={() => setOpen(true)}
            variant="blockPrimary"
            title={t('save_contact')}
            size={w >= 4 && h > 4 ? 'block' : 'blockSm'}
            className="w-full px-fluid-1!"
          >
            <ArrowWallDownIcon className="size-fluid-4 shrink-0" />
            {w >= 4 && <span className="ml-fluid-2">{t('save')}</span>}
          </Button>
        </span>
      </div>
    </div>
  )
}
