import { PartialMessage } from '@bufbuild/protobuf'
import { StoreProductType } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/store_product_type_pb'
import { ProductMeta } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { Media } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/media_pb'
import {
  ArrowUpRightIcon,
  Basket2Icon,
  GraduateCapIcon,
} from '@fingertip/icons'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import { getProductPrice } from '@/components/product/product-price'
import { BlockCarousel } from '@/components/shared/block-carousel'

import { BlockAssetInner } from '../shared/block-asset-inner'
import { BlockProps } from '../shared/block-switch'

export const StoreProductInner = ({
  blockMeta,
  w,
  h,
  y,
  domain,
  siteSlug,
  block,
  handleClick,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const meta = blockMeta?.meta?.metaContent?.value as ProductMeta
  const products = meta?.products || []

  if (!products.length) {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('products_are_coming_soon')}</p>
      </div>
    )
  }

  return (
    <BlockCarousel
      count={products.length}
      viewportClassName="rounded-page-widget-inner"
      options={{
        startIndex: (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
      }}
    >
      {products.map((product) => {
        const { buttonText } = getProductPrice({
          priceInCents: product?.product?.pricing?.priceInCents || 0,
          salePriceInCents: product?.product?.pricing?.salePriceInCents || 0,
          maxPriceInCents: product?.product?.pricing?.maxPriceInCents || 0,
          hasSale: product?.product?.pricing?.hasSale,
          currency: product?.product?.pricing?.currency || 'usd',
          isRange: product?.product?.pricing?.isRange,
          isSoldOut: product?.product?.isSoldOut,
          t,
        })

        const storeProductMedia = product?.product?.mediaAssets?.[0]
        const media: PartialMessage<Media> | undefined =
          storeProductMedia?.media

        let icon = <Basket2Icon className="size-fluid-4" />

        if (
          [StoreProductType.COURSE, 'STORE_PRODUCT_TYPE_COURSE'].includes(
            (product?.product?.type || StoreProductType.UNSPECIFIED) as any,
          )
        ) {
          icon = <GraduateCapIcon className="size-fluid-4" />
        }

        return (
          <Link
            key={product?.product?.id || ''}
            onClick={() => handleClick?.(block, product?.product?.name)}
            className="flex size-full"
            href={`${domain ? '/' : `/${siteSlug}/`}products/${product?.product?.slug}`}
            replace={false}
          >
            <BlockAssetInner
              media={media}
              title={product?.product?.name}
              description={product?.product?.excerpt}
              buttonText={buttonText}
              icon={icon}
              w={w}
              h={h}
              y={y}
              buttonIcon={
                <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
              }
              scaleFactor={scaleFactor}
            />
          </Link>
        )
      })}
    </BlockCarousel>
  )
}
