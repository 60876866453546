import { type SkillItem } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowRightIcon, StarIcon } from '@fingertip/icons'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { IconBox } from '@/components/shared/icon-box'
import { ButtonDiv } from '@/components/ui/button'
import { useOverflow } from '@/lib/hooks/use-overflow'
import { cn } from '@/lib/utils/cn'

import { SkillList } from './skill-list'

type Props = {
  h: number
  w: number
  title?: string
  items: SkillItem[]
}

export const SkillInner = ({ title, items, h, w }: Props) => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null)
  const { visibleCount } = useOverflow({ w, h, items, containerRef })
  const isShort = h <= 4

  if (h < 4) {
    return (
      <div className="flex size-full flex-row items-center justify-between gap-fluid-1">
        {title && (
          <div className="page-heading line-clamp-2 text-left text-fluid-lg">
            {title}
          </div>
        )}

        <ButtonDiv size="blockSm" variant="blockPrimary">
          {t('view_all')}
          <ArrowRightIcon className="ml-fluid-2 size-fluid-4" />
        </ButtonDiv>
      </div>
    )
  }

  return (
    <>
      {h >= 6 && (
        <IconBox className="mb-fluid-3">
          <StarIcon className="size-fluid-4" />
        </IconBox>
      )}

      {title && (
        <div className="mb-fluid-2 shrink-0">
          <div className="page-heading line-clamp-2 grow text-fluid-lg">
            {title}
          </div>
        </div>
      )}

      <div className="flex-1 overflow-hidden text-left" ref={containerRef}>
        <SkillList items={items} visibleCount={visibleCount} />
      </div>

      {visibleCount >= items.length ? (
        <div />
      ) : (
        <ButtonDiv
          size={isShort ? 'blockSm' : 'block'}
          variant="blockPrimary"
          className={cn({
            'w-full': !isShort,
          })}
        >
          {t('view_all')} {items.length}
          <ArrowRightIcon className="ml-fluid-2 size-fluid-4" />
        </ButtonDiv>
      )}
    </>
  )
}
