import { OpeningHoursDay } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { format, isAfter, isEqual, isWithinInterval } from 'date-fns'

import { weekdayLabels } from './constants'
import { Days, RangeItem, Time } from './types'

const militaryTimeToDate = (str: string) => {
  const hours = parseInt(str.substring(0, 2), 10)
  const minutes = parseInt(str.substring(2, 4), 10)
  const date = new Date()
  date.setMilliseconds(0)
  date.setSeconds(0)
  date.setMinutes(Number(minutes))
  date.setHours(Number(hours))

  return {
    date,
    hours,
    minutes,
  }
}

const transformDay = (day: OpeningHoursDay | undefined) => {
  return (day?.times || []).map(({ startTime, endTime }) => {
    return {
      start: startTime ? militaryTimeToDate(startTime) : undefined,
      end: endTime ? militaryTimeToDate(endTime) : undefined,
    }
  })
}

export const transformOpeningHours = (props: {
  monday: OpeningHoursDay | undefined
  tuesday: OpeningHoursDay | undefined
  wednesday: OpeningHoursDay | undefined
  thursday: OpeningHoursDay | undefined
  friday: OpeningHoursDay | undefined
  saturday: OpeningHoursDay | undefined
  sunday: OpeningHoursDay | undefined
}) => {
  return [
    transformDay(props.monday),
    transformDay(props.tuesday),
    transformDay(props.wednesday),
    transformDay(props.thursday),
    transformDay(props.friday),
    transformDay(props.saturday),
    transformDay(props.sunday),
  ]
}

export const isOpen = (times: Time[]): boolean => {
  let isOpen = false

  const currentDate = new Date()

  for (const time of times) {
    if (
      !time.start ||
      !time.end ||
      isAfter(time.start.date, time.end.date) ||
      isEqual(time.start.date, time.end.date)
    ) {
      continue
    }

    const isCurrentOpen = isWithinInterval(currentDate, {
      start: time.start.date,
      end: time.end.date,
    })

    if (isCurrentOpen) {
      isOpen = true
      break
    }
  }

  return isOpen
}

const nextOpeningDay = (days: Days, currentDayIndex: number) => {
  for (let i = 0; i < 7; i++) {
    const dayIndex = (currentDayIndex + i) % 7
    const times = days[dayIndex]

    for (const time of times) {
      if (!time.start || !time.end) {
        continue
      }

      return {
        day: dayIndex,
        time: format(time.start.date, 'h:mm'),
        amPm: format(time.start.date, 'aaa'),
      }
    }
  }

  return null
}

export const getStatusText = ({
  days,
  currentDayIndex,
  t,
}: {
  days: Days
  currentDayIndex: number
  t: any
}) => {
  const today = days[currentDayIndex]

  if (isOpen(today) && today?.[0]?.end) {
    return {
      top: t('closes'),
      bottom: format(today[0].end.date, 'h:mm'),
      bottomMeta: format(today[0].end.date, 'aaa'),
    }
  }

  const nextOpen = nextOpeningDay(days, currentDayIndex)

  if (!nextOpen) {
    return {
      bottom: t('closed'),
    }
  }

  return {
    top: `${t('opens')} ${
      nextOpen.day === currentDayIndex ? '' : weekdayLabels[nextOpen.day]
    }`,
    bottom: nextOpen.time,
    bottomMeta: nextOpen.amPm,
  }
}

const timeToText = ({
  range1,
  range2,
  isStart,
  showMinutes,
  use24Hour,
  t,
}: {
  range1: RangeItem
  range2?: RangeItem
  isStart?: boolean
  showMinutes?: boolean
  use24Hour?: boolean
  t: any
}) => {
  let amPmFormat = use24Hour ? '' : 'aaa'
  let hourFormat = use24Hour ? 'H' : 'h'
  let minuteFormat = ':mm'

  if (!range2 && isStart) {
    if (range1.hours === 0 && range1.minutes === 0) {
      return t('open_24_hours')
    }
  }

  if (!use24Hour && range2) {
    if (range1.hours < 12 && range2?.hours < 12) {
      amPmFormat = ''
    } else if (range1.hours >= 12 && range2?.hours >= 12) {
      amPmFormat = ''
    }
  }

  if (range1.minutes === 0 && !showMinutes) {
    minuteFormat = ''
  }

  return format(range1.date, `${hourFormat}${minuteFormat}${amPmFormat}`)
}

export const getTimesText = (
  times: Time[],
  showMinutes: boolean,
  separator: string = '–',
  use24Hour: boolean = false,
  t: any,
) => {
  const timeRanges = times
    .filter((time) => time.start)
    .map((time) => {
      if (!time?.start || !time?.end) {
        return t('closed')
      }

      if (
        time?.start?.hours === 0 &&
        (time?.end?.hours === 24 || time?.end?.hours === 0)
      ) {
        return t('open_24_hours')
      }

      const open = timeToText({
        range1: time.start,
        range2: time.end,
        isStart: true,
        showMinutes,
        use24Hour,
        t,
      })
      const close = time.end
        ? `${separator}${timeToText({
            range1: time.end,
            showMinutes,
            use24Hour,
            t,
          })}`
        : ''
      return `${open}${close}`
    })

  if (timeRanges.length === 0) {
    return t('closed')
  }

  return `${timeRanges.join(', ')}`
}

export const getTimeText = (
  time: Time,
  showMinutes: boolean,
  separator: string = '–',
  use24Hour: boolean = false,
  t: any,
) => {
  if (!time.start) {
    return t('closed')
  } else if (
    time.end &&
    time.start.hours === 0 &&
    (time.end.hours === 24 || time.end.hours === 0)
  ) {
    return t('open_24_hours_1')
  } else {
    const open = timeToText({
      range1: time.start!,
      range2: time.end,
      isStart: true,
      showMinutes,
      use24Hour,
      t,
    })
    const close = time.end
      ? `${separator}${timeToText({
          range1: time.end,
          showMinutes,
          use24Hour,
          t,
        })}`
      : ''
    return `${open}${close}`
  }
}

// Define 24-hour regions
export const TWENTY_FOUR_HOUR_LOCALES = [
  'de', // German-speaking countries
  'fr', // French-speaking countries
  'es', // Spanish-speaking countries (except some Latin American countries)
  'it', // Italy
  'pt', // Portuguese-speaking countries
  'ru', // Russian-speaking countries
  'pl', // Poland
  'nl', // Netherlands
  'sv', // Sweden
  'fi', // Finland
  'no', // Norway
  'da', // Denmark
  'cs', // Czech Republic
  'sk', // Slovakia
  'hu', // Hungary
  'ro', // Romania
  'bg', // Bulgaria
  'el', // Greece
  'tr', // Turkey
  'uk', // Ukraine
  'ja', // Japan
  'ko', // Korea
  'zh', // China
  'vi', // Vietnam
]
