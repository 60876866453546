import { DotGrid1x3HorizontalIcon } from '@fingertip/icons'

import { cn } from '@/lib/utils'

import { PageBreakStylesProps } from './page-break-switch'

export const Ellipsis = ({ className }: PageBreakStylesProps) => {
  return (
    <div
      className={cn(
        'flex w-full items-center justify-center text-page-divider',
        className,
      )}
    >
      <DotGrid1x3HorizontalIcon className="size-fluid-8" />
    </div>
  )
}
