import { FaqContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useState } from 'react'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import {
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { cn } from '@/lib/utils/cn'

import { BlockProps } from '../shared/block-switch'
import { FaqInner } from './faq-inner'

export const FaqBlock = ({
  block,
  w,
  h,
  viewportWidth,
  scaleFactor,
}: BlockProps) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const content = block?.content?.content?.value as FaqContent

  const handleAccordionChange = (value: string[]) => {
    setExpandedItems(value)
  }

  return (
    <ResponsiveSheet open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className={cn(
          'cursor-pointer outline-hidden! flex size-full flex-col p-block',
          {
            'justify-center h-full': h <= 2,
          },
        )}
      >
        <FaqInner
          content={content}
          w={w}
          h={h}
          onFaqClick={(item) => setExpandedItems((prev) => [...prev, item])}
          viewportWidth={viewportWidth}
          scaleFactor={scaleFactor}
        />
      </DialogTrigger>

      <ResponsiveSheetContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
        {content?.title && (
          <DialogHeader className="w-full pb-fluid-2 pt-fluid-4">
            <DialogTitle className="text-center">{content.title}</DialogTitle>
          </DialogHeader>
        )}

        <div className="flex-1">
          <Accordion
            type="multiple"
            value={expandedItems}
            onValueChange={handleAccordionChange}
          >
            {(content?.faqs || [])
              .filter((item) => !!item?.question || !!item?.answer)
              .map((item, index) => (
                <AccordionItem key={index} value={String(index)}>
                  <AccordionTrigger hideBackground>
                    {item?.question || ''}
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className="prose prose-sm text-current prose-headings:text-current prose-p:text-current prose-a:text-current prose-a:underline prose-strong:text-current prose-em:text-current"
                      dangerouslySetInnerHTML={{ __html: item?.answer || '' }}
                    />
                  </AccordionContent>
                </AccordionItem>
              ))}
          </Accordion>
        </div>
      </ResponsiveSheetContent>
    </ResponsiveSheet>
  )
}
