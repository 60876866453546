import { RatingsMeta } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowUpRightIcon } from '@fingertip/icons'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { Session } from '@supabase/supabase-js'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RatingDialog } from '@/components/rating/rating-dialog'
import { BlockCarousel } from '@/components/shared/block-carousel'
import { ButtonDiv } from '@/components/ui/button'
import { DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { useBreakpoint } from '@/lib/hooks/use-breakpoint'
import { getSupabaseClient } from '@/lib/supabase/supabase-client'
import { cn } from '@/lib/utils/cn'
import { omitParenthesisText } from '@/lib/utils/string-utils'

import { BlockProps } from '../shared/block-switch'
import { ReviewItem } from './review-item'
import { WithoutReviews } from './without-reviews'

type Props = {
  meta: RatingsMeta
  ratingsAverage: number
  ratingCount: number
} & Pick<BlockProps, 'w' | 'h' | 'siteSlug'>

export const FingertipWithReviews = ({
  meta,
  ratingsAverage,
  ratingCount,
  h,
  w,
  siteSlug,
}: Props) => {
  const { t } = useTranslation()
  const [activeReview, setActiveReview] = useState<number | null>(null)
  const breakpoint = useBreakpoint()

  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    const getData = async () => {
      const supabase = getSupabaseClient()

      const {
        data: { session },
      } = await supabase.auth.getSession()

      setSession(session)
    }

    getData()
  }, [])

  const reviews = useMemo(() => {
    const unprocessedReviews = meta?.reviews || []
    return unprocessedReviews.map((review) => {
      return {
        ...review,
        reviewerName: omitParenthesisText(review.name),
      }
    })
  }, [meta?.reviews])

  return (
    <>
      <div className="flex size-full flex-col rounded-page-widget-inner">
        <RatingDialog siteSlug={siteSlug} pageSlug="index" session={session}>
          <div className="p-block block w-full cursor-pointer">
            <WithoutReviews
              source="FINGERTIP"
              h={h}
              w={w}
              ratingsAverage={ratingsAverage}
              ratingCount={ratingCount}
            />
          </div>
        </RatingDialog>

        <BlockCarousel
          count={reviews.length}
          viewportClassName="px-block rounded-b-page-widget-inner"
        >
          {reviews.map((review, index) => (
            <div
              key={index}
              className={cn('pb-block flex size-full', {
                'pr-fluid-3': reviews.length > 1,
              })}
            >
              <button
                type="button"
                onClick={() => setActiveReview(index)}
                className="rounded-page-widget-block cursor-pointer bg-page-card-background border-page-card shadow-page-card flex size-full flex-col justify-between space-y-fluid-1 p-fluid-3 text-left text-page-card-text"
              >
                <ReviewItem
                  review={review}
                  textClassName={cn('line-clamp-2 leading-snug', {
                    'line-clamp-4': h === 8,
                    'line-clamp-6': h === 9,
                    'line-clamp-7': h === 10,
                    'line-clamp-9': h > 10,
                  })}
                />

                {h > 6 && (
                  <div>
                    <ButtonDiv variant="blockSecondary" size="blockSm">
                      {t('read_full_review')}
                      <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4" />
                    </ButtonDiv>
                  </div>
                )}
              </button>
            </div>
          ))}
        </BlockCarousel>
      </div>

      {activeReview !== null && (
        <ResponsiveSheet
          open={activeReview !== null}
          modal={breakpoint !== 'sm'}
          onOpenChange={(open) => {
            if (!open) {
              setActiveReview(null)
            }
          }}
        >
          <ResponsiveSheetContent
            desktopClassName="max-h-full overflow-auto"
            mobileClassName="w-full max-h-[calc(100vh-100px)] min-w-full overflow-auto"
          >
            <VisuallyHidden>
              <DialogTitle>Review</DialogTitle>
            </VisuallyHidden>

            <div className="w-full px-fluid-5 py-fluid-8">
              <ReviewItem review={reviews[activeReview]} />
            </div>
          </ResponsiveSheetContent>
        </ResponsiveSheet>
      )}
    </>
  )
}
