import { RefObject, useEffect, useState } from 'react'

type Props = {
  containerHeight: number
  linkHeight: number
  containerPadding?: number
  gap?: number
  titleRef: RefObject<HTMLDivElement | null>
  containerRef: RefObject<HTMLDivElement | null>
  items: any[]
  buttonHeight: number
  min?: number
  scaleFactor: number
}

export const useHorizontalVisibleCount = ({
  containerHeight,
  linkHeight,
  titleRef,
  containerPadding = 12,
  gap = 8,
  containerRef,
  items,
  buttonHeight,
  min = 1,
  scaleFactor,
}: Props) => {
  const [visibleCount, setVisibleCount] = useState(0)
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const title = titleRef.current
    if (!title) return

    const checkOverflow = () => {
      const totalTitleHeight = title.clientHeight
      const totalContainerHeight =
        containerHeight -
        containerPadding * scaleFactor -
        containerPadding * scaleFactor -
        totalTitleHeight
      const totalItemHeight = (linkHeight + gap) * scaleFactor

      // First, try to fit all items
      let maxItems = Math.floor(totalContainerHeight / totalItemHeight)

      // If we can't fit all items, we need to show the button
      if (maxItems < items.length) {
        setShowButton(true)
        // Recalculate maxItems with button height
        maxItems = Math.floor(
          (totalContainerHeight - buttonHeight * scaleFactor) / totalItemHeight,
        )
      } else {
        setShowButton(false)
      }

      // Calculate the visible count
      const calculatedVisibleCount = Math.min(maxItems, items.length)

      // If the calculated visible count is less than min, set it to 0
      // Otherwise, use the calculated value
      setVisibleCount(calculatedVisibleCount < min ? 0 : calculatedVisibleCount)
    }

    checkOverflow()
  }, [
    containerHeight,
    linkHeight,
    gap,
    items.length,
    containerRef,
    buttonHeight,
    min,
    titleRef,
    containerPadding,
    scaleFactor,
  ])

  return { visibleCount, showButton }
}
