import { SkillsContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useRef, useState } from 'react'

import {
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { cn } from '@/lib/utils'

import { BlockProps } from '../shared/block-switch'
import { SkillInner } from './skills-inner'

export const SkillsBlock = ({ block, h, w }: BlockProps) => {
  const [open, setOpen] = useState(false)
  const content = block?.content?.content?.value as SkillsContent
  const containerRef = useRef<HTMLDivElement>(null)

  const title = content?.title
  const items = (content?.skills || []).filter((item) => !!item?.title)

  return (
    <ResponsiveSheet open={open} onOpenChange={setOpen}>
      <DialogTrigger className="cursor-pointer p-block flex size-full flex-col outline-hidden! text-left">
        <SkillInner items={items} title={title} h={h} w={w} />
      </DialogTrigger>

      <ResponsiveSheetContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
        {title && (
          <DialogHeader className="w-full pt-fluid-4">
            <DialogTitle className="text-center">{content?.title}</DialogTitle>
          </DialogHeader>
        )}

        <div className="flex-1 pt-fluid-2">
          <div className="space-y-fluid-1" ref={containerRef}>
            {items.map((item, index) => (
              <div
                key={index}
                className={cn(
                  'inline-block border border-page-body-text rounded-page-widget-block text-fluid-sm px-fluid-2 py-fluid-1 mb-fluid-1 mr-fluid-1 text-left',
                )}
              >
                <div className="line-clamp-1">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </ResponsiveSheetContent>
    </ResponsiveSheet>
  )
}
