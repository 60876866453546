import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useState } from 'react'

import { RootStoreContext } from '@/lib/stores/root-store'

import { BlockProps } from '../shared/block-switch'
import { ContactDialog } from './contact-dialog'
import { ContactInner } from './contact-inner'

export const ContactBlock = observer(
  ({ block, handleClick, w, h, siteSlug }: BlockProps) => {
    const {
      publicStore: { setDialogBlockId, dialogBlockId },
    } = useContext(RootStoreContext)
    const [open, setOpen] = useState(false)

    const isOpen = dialogBlockId === block.id || open

    const handleOpenChange = useCallback(
      (open: boolean) => {
        setOpen(open)

        if (!open) {
          setDialogBlockId(null)
        }
      },
      [setDialogBlockId],
    )

    return (
      <>
        <ContactInner
          block={block}
          handleClick={handleClick}
          w={w}
          h={h}
          setOpen={setOpen}
        />

        <ContactDialog
          block={block}
          handleClick={handleClick}
          open={isOpen}
          onOpenChange={handleOpenChange}
          siteSlug={siteSlug}
        />
      </>
    )
  },
)
