import { AffiliateContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { LogoStandaloneIcon } from '@fingertip/icons'
import { useTranslation } from 'react-i18next'

import { BlockProps } from '../shared/block-switch'

export const AffiliateBlock = ({ block, handleClick }: BlockProps) => {
  const { t } = useTranslation()

  const content = block?.content?.content?.value as AffiliateContent

  return (
    <a
      className="p-block flex size-full flex-row items-center justify-center space-x-fluid-2"
      href={content?.websiteUrl}
      onClick={() => handleClick?.(block, `${content?.title}`)}
      target="_blank"
    >
      <div>
        <LogoStandaloneIcon className="relative -top-px size-fluid-6 shrink-0 text-current" />
      </div>

      <div className="page-heading line-clamp-2 text-fluid-lg">
        {content?.title || t('get_20_off_fingertip')}
      </div>
    </a>
  )
}
