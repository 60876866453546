import Script from 'next/script'
import { useMemo } from 'react'

import { Spinner } from '@/components/shared/spinner'

import { getFullHeight, getFullWidth } from './utils'

type Props = {
  html: string
  w: number
  h: number
  color?: string
  background?: string
  viewportWidth: number
  scaleFactor: number
}

export const EmbedBlock = ({
  html,
  h,
  w,
  color,
  background,
  scaleFactor,
}: Props) => {
  const data = useMemo(() => {
    const WIDTH = getFullWidth(w, scaleFactor)
    const HEIGHT = getFullHeight(h, scaleFactor)
    const newHtml = html.replaceAll('maxheight=600', `maxheight=${HEIGHT}`)
    return { width: WIDTH, height: HEIGHT, html: newHtml }
  }, [w, scaleFactor, h, html])

  if (!data.html) {
    return null
  }

  return (
    <div
      className="relative"
      style={{ width: data.width, height: data.height, color, background }}
    >
      <div className="absolute left-fluid-0 top-fluid-5 size-full">
        <Spinner />
      </div>

      <Script src="//cdn.iframe.ly/embed.js" strategy="lazyOnload" />
      <div dangerouslySetInnerHTML={{ __html: data.html }} />
    </div>
  )
}
