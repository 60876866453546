import { cn } from '@/lib/utils/cn'

type Props = {
  title: string
  position?: 'top' | 'bottom'
  w: number
  h: number
}

export const AbsoluteTitle = ({ title, position = 'bottom', w }: Props) => {
  const isNarrow = w <= 2

  return (
    <div
      className={cn(
        'z-20 absolute flex max-w-[calc(100%-16px)] bg-page-card-background border-page-card px-3 py-1 text-page-card-text truncate rounded-page-widget-block',
        {
          'left-2 top-2': position === 'top' && isNarrow,
          'bottom-2 left-2': position === 'bottom' && isNarrow,
          'left-3 top-3': position === 'top' && !isNarrow,
          'bottom-3 left-3': position === 'bottom' && !isNarrow,
          'text-[10px]': isNarrow,
          'text-xs': !isNarrow,
        },
      )}
    >
      {title}
    </div>
  )
}
