import { PartialMessage } from '@bufbuild/protobuf'
import { TestimonialItem as ProtoTestimonialItem } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { getInitials } from '@/lib/utils/string-utils'

import { MediaImage } from '../shared/media-image'

type Props = {
  testimonial?: PartialMessage<ProtoTestimonialItem>
  fallback: {
    testimonialText: string
    initial: string
    name: string
    subtitle: string
  }
  textClassName?: string
}

export const TestimonialItem = ({
  textClassName,
  testimonial,
  fallback,
}: Props) => {
  return (
    <>
      <div className={textClassName}>
        {testimonial?.text || fallback.testimonialText}
      </div>

      <div className="mt-0 flex items-center justify-between pt-fluid-4">
        <div className="flex items-center">
          {testimonial?.media?.type ? (
            <MediaImage
              media={testimonial?.media}
              className="mr-fluid-2 size-fluid-10! min-w-fluid-10"
              width={40}
              height={40}
              style={{
                borderRadius: 9999,
                overflow: 'hidden',
              }}
              title={testimonial?.name || ''}
            />
          ) : (
            <Avatar className="mr-fluid-2 size-fluid-10! min-w-fluid-10">
              <AvatarFallback
                value={getInitials(testimonial?.name || fallback.initial)}
                noColor
              />
            </Avatar>
          )}

          <div className="flex flex-col">
            <span className="truncate">
              {testimonial?.name || fallback.name}
            </span>

            <span className="line-clamp-2 text-fluid-sm">
              {testimonial?.subtitle || fallback.subtitle}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
