import { QuotationContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { QuoteIcon } from '@fingertip/icons'

import { convertToOverlayNumber } from '@/components/page-theme/theme.utils'
import { cn } from '@/lib/utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'
import { getFullHeight, getFullWidth } from '../shared/utils'

export const QuotationBlock = ({ block, w, h, y, scaleFactor }: BlockProps) => {
  const content = block?.content?.content?.value as QuotationContent
  const hasAsset = !!content?.media?.type

  const WIDTH = getFullWidth(w, scaleFactor)
  const HEIGHT = getFullHeight(h, scaleFactor)
  const overlayNumber =
    hasAsset && content?.media?.overlayBackground
      ? convertToOverlayNumber(content?.media?.overlayBackground)
      : 0
  let luminance: 'DARK' | 'LIGHT' | null = null

  if (overlayNumber > 0) {
    luminance = 'DARK'
  } else if (overlayNumber < 0) {
    luminance = 'LIGHT'
  }

  return (
    <div className="flex size-full items-center">
      {hasAsset && (
        <MediaImage
          media={content?.media}
          className="absolute overflow-hidden rounded-page-widget-inner object-cover"
          width={WIDTH}
          height={HEIGHT}
          priority={y < 6}
          style={{
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
          }}
        />
      )}

      <div className="p-block z-10 flex flex-col">
        <div
          className={cn(
            'page-heading absolute left-fluid-3 top-fluid-3 opacity-30',
            {
              'text-white!': luminance === 'DARK',
              'text-black!': luminance === 'LIGHT',
            },
          )}
        >
          <QuoteIcon className="size-fluid-12" />
        </div>

        <div
          className={cn(
            'page-heading text-fluid-lg grow pt-fluid-5 text-left',
            {
              'text-white!': luminance === 'DARK',
              'text-black!': luminance === 'LIGHT',
            },
          )}
        >
          {content?.text}
        </div>

        {content?.fullName && (
          <div
            className={cn('mt-fluid-4 line-clamp-2', {
              'text-white!': luminance === 'DARK',
              'text-black!': luminance === 'LIGHT',
            })}
          >
            &#8212; {content?.fullName}
          </div>
        )}
      </div>
    </div>
  )
}
