import { DottedLine } from './dotted-line'
import { Ellipsis } from './ellipsis'
import { Line } from './line'
import { ThickLine } from './thick-line'
import { ThinLine } from './thin-line'

export const PageBreakStyles = {
  LINE: {
    label: 'Regular line',
  },
  THIN_LINE: {
    label: 'Thin line',
  },
  THICK_LINE: {
    label: 'Thick line',
  },
  DOTTED_LINE: {
    label: 'Dotted line',
  },
  ELLIPSIS: {
    label: 'Ellipsis',
  },
  HIDDEN: {
    label: 'Hidden',
  },
}

export type PageBreakStyles = keyof typeof PageBreakStyles

export type PageBreakStylesProps = {
  className?: string
}

type Props = {
  styleSlug: PageBreakStyles
} & PageBreakStylesProps

export const PageBreakSwitch = ({ styleSlug, ...props }: Props) => {
  switch (styleSlug) {
    case 'LINE':
      return <Line {...props} />
    case 'THIN_LINE':
      return <ThinLine {...props} />
    case 'ELLIPSIS':
      return <Ellipsis {...props} />
    case 'THICK_LINE':
      return <ThickLine {...props} />
    case 'DOTTED_LINE':
      return <DottedLine {...props} />
    default:
      return null
  }
}
