import { ImageComparisonContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowExpandHorIcon } from '@fingertip/icons'
import { useCallback, useRef, useState } from 'react'

import { cn } from '@/lib/utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'
import { getFullHeight, getFullWidth } from '../shared/utils'

export const ImageComparisonBlock = ({
  block,
  w,
  h,
  y,
  scaleFactor,
}: BlockProps) => {
  const [sliderPosition, setSliderPosition] = useState(50) // Initial slider position at 50%
  const sliderRef = useRef<HTMLDivElement>(null)

  const content = block?.content?.content?.value as ImageComparisonContent

  const assets = content?.assets || []

  const WIDTH = getFullWidth(w, scaleFactor)
  const HEIGHT = getFullHeight(h, scaleFactor)

  let gradient = (36 / HEIGHT) * 100

  const onDragging = useCallback((e: PointerEvent) => {
    if (!sliderRef.current) return
    const rect = sliderRef.current.getBoundingClientRect()
    const newSliderPosition = ((e.clientX - rect.left) / rect.width) * 100
    setSliderPosition(Math.max(0, Math.min(100, newSliderPosition)))
  }, [])

  const stopDragging = useCallback(() => {
    document.removeEventListener('pointermove', onDragging)
    document.removeEventListener('pointerup', stopDragging)
  }, [onDragging])

  const startDragging = useCallback(
    (e: React.PointerEvent) => {
      e.preventDefault()
      document.addEventListener('pointermove', onDragging)
      document.addEventListener('pointerup', stopDragging)
    },
    [onDragging, stopDragging],
  )

  return (
    <div
      className="relative size-full overflow-hidden rounded-page-widget-inner bg-page-background"
      ref={sliderRef}
    >
      <div
        className="absolute inset-0 z-20 flex -translate-x-1/2 cursor-ew-resize items-center justify-center"
        onPointerDown={startDragging}
        style={{ left: `${sliderPosition}%`, touchAction: 'none' }}
      >
        <div className="rounded-page-widget-block flex size-fluid-12 min-w-fluid-12 items-center justify-center bg-black text-white shadow-sm">
          <ArrowExpandHorIcon className="size-fluid-4" />
        </div>

        <div className="absolute h-full w-fluid-1 bg-black" />
      </div>

      {assets
        .filter((asset) => !!asset?.media)
        .map((asset, index) => {
          return (
            <div
              className="absolute inset-0 min-h-full w-full"
              key={index}
              style={
                index === 0
                  ? {
                      zIndex: 1,
                      clipPath: `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0% 100%)`,
                    }
                  : undefined
              }
            >
              <MediaImage
                width={WIDTH}
                height={HEIGHT}
                media={asset?.media}
                priority={index === 0 && y < 6}
              />
            </div>
          )
        })}

      {assets
        .filter((asset) => !!asset?.media)
        .map((asset, index) => {
          return (
            <div className="absolute inset-0 min-h-full w-full" key={index}>
              {asset?.title && (
                <>
                  {index === 0 && (
                    <div
                      className="absolute inset-x-0 top-0 z-10 size-full rounded-page-widget-inner"
                      style={{
                        background: `linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.35) ${gradient / 2}%, rgba(0,0,0,0) ${gradient}%)`,
                      }}
                    />
                  )}

                  <div
                    className={cn(
                      'text-fluid-sm px-block pt-block absolute top-0 z-20 block w-full text-white!',
                      {
                        'left-0': index === 0,
                        'right-0 text-right': index === 1,
                      },
                    )}
                  >
                    <div className="line-clamp-1">{asset?.title}</div>
                  </div>
                </>
              )}
            </div>
          )
        })}
    </div>
  )
}
