/* eslint-disable @next/next/no-img-element */
import {
  SocialFeedContent,
  SocialFeedMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { sentenceCase } from 'change-case'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { IconBox } from '@/components/shared/icon-box'
import { SocialIcon } from '@/components/shared-page/social-icon'
import { ButtonDiv } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import { SocialKeys, socials } from '@/lib/data/socials'
import { PHEventName } from '@/lib/posthog/events'
import { cn } from '@/lib/utils'
import { socialUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'
import { formatLargeNumbers } from '../shared/utils'

export const SocialFeedBlock = ({
  block,
  blockMeta,
  handleClick,
  h,
  siteSlug,
}: BlockProps) => {
  const { t } = useTranslation()
  const postHog = usePostHog()
  const content = block?.content?.content?.value as SocialFeedContent
  const meta = blockMeta?.meta?.metaContent?.value as SocialFeedMeta

  const currentSocial = socials[content?.key as SocialKeys]

  const posts = meta?.posts || []

  // This is currently here so we can debug/track when image is not loading on client side.
  useEffect(() => {
    if (posts.length > 0) {
      fetch(posts[0].imgSrc).catch(() => {
        postHog.capture(PHEventName.SOCIAL_FEED_POST_IMAGE_ERROR, {
          img_src: posts[0].imgSrc,
          block_id: block.id,
          site_slug: siteSlug,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!content?.key) {
    return null
  }

  if (posts.length === 0 && !meta?.username && !meta?.followersCount) {
    return (
      <div className="flex size-full flex-col justify-between overflow-hidden rounded-page-widget-inner p-fluid-4">
        <div className="mb-fluid-3 flex items-center justify-between">
          <div className="flex items-center">
            <IconBox>
              <SocialIcon
                kind={content?.key as SocialKeys}
                className="size-fluid-4 transition-all"
              />
            </IconBox>

            <div className="opacity-50">
              <Skeleton className="ml-fluid-2 h-fluid-8 w-fluid-16 bg-page-body-text" />
            </div>
          </div>

          <ButtonDiv variant="blockPrimary" size="blockSm">
            <span>{currentSocial.buttonLabel}</span>
          </ButtonDiv>
        </div>

        <div className="grid grid-cols-3 gap-fluid-3 opacity-50">
          {Array.from({ length: h > 9 ? 6 : 3 }).map((_, index) => (
            <Skeleton
              key={index}
              className={cn(
                'w-full object-cover object-center bg-page-body-text',
                {
                  'aspect-youtube': content.key === 'youtube',
                  'aspect-tiktok': content.key === 'tiktok',
                  'aspect-square': content.key === 'instagram',
                },
              )}
            />
          ))}
        </div>
      </div>
    )
  }

  const filteredPoints = posts.slice(0, h > 9 ? 6 : 3)

  // fetch one post image if not good result send even to postHog.

  return (
    <div
      title={block.name}
      className="p-block flex size-full flex-col justify-between overflow-hidden rounded-page-widget-inner"
    >
      <a
        onClick={() =>
          handleClick?.(block, `${sentenceCase(content.key)} social feed`)
        }
        href={socialUrl({
          websiteUrl: content?.websiteUrl,
          key: content?.key,
        })}
        target={!!content?.websiteUrl ? '_blank' : undefined}
        className="mb-fluid-3 flex items-center justify-between"
      >
        <div className="flex items-center">
          <IconBox>
            <SocialIcon
              kind={content?.key as SocialKeys}
              className="size-fluid-4 transition-all"
            />
          </IconBox>

          <div className="page-heading ml-fluid-2 line-clamp-1 text-left text-fluid-lg">
            {content?.title || meta?.username || currentSocial.name}
          </div>
        </div>

        <ButtonDiv size="blockSm" variant="blockPrimary">
          <span>{currentSocial.buttonLabel}</span>

          {meta?.followersCount && (
            <span className="ml-fluid-2 opacity-70">
              {formatLargeNumbers(meta.followersCount)}
            </span>
          )}
        </ButtonDiv>
      </a>

      <div className="flex flex-1 flex-col justify-center">
        <div className="grid grid-cols-3 gap-fluid-3">
          {filteredPoints.map((post, index) => {
            return (
              <a
                href={post?.url || '#'}
                onClick={() =>
                  handleClick?.(
                    block,
                    `${sentenceCase(content.key)} social feed`,
                  )
                }
                target={!!post?.url ? '_blank' : undefined}
                key={index}
                className="rounded-page-widget-block overflow-hidden"
              >
                <img
                  src={post.imgSrc}
                  alt={post.title}
                  className={cn('w-full object-cover object-center', {
                    'aspect-youtube': content.key === 'youtube',
                    'aspect-tiktok': content.key === 'tiktok',
                    'aspect-square': content.key === 'instagram',
                  })}
                />
              </a>
            )
          })}
        </div>

        {filteredPoints.length === 0 && (
          <div>
            <div className="text-center text-fluid-sm">{t('no_posts_yet')}</div>
          </div>
        )}
      </div>
    </div>
  )
}
