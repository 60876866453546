import { CalendlyEmbedContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { CalendlyBlock } from '@/components/blocks/calendly-embed/calendly-block'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'

const MemoizedEmbedBlock = memo(CalendlyBlock)

export const CalendlyEmbedBlock = ({
  block,
  h,
  context,
  viewportWidth,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as CalendlyEmbedContent
  const showFallback = context === 'SEARCH'

  return (
    <div className="flex h-full items-center justify-center overflow-hidden rounded-page-widget-inner">
      {!showFallback && content?.websiteUrl && (
        <MemoizedEmbedBlock
          websiteUrl={prependUrl(content?.websiteUrl)}
          h={h}
          key={content?.websiteUrl}
          viewportWidth={viewportWidth}
          scaleFactor={scaleFactor}
        />
      )}

      {showFallback && <div className="text-center">{t('calendly')}</div>}
    </div>
  )
}
