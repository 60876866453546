import { FigmaEmbedContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockProps } from '../shared/block-switch'
import { EmbedBlock } from '../shared/embed-block'

const MemoizedEmbedBlock = memo(EmbedBlock)

export const FigmaEmbedBlock = ({
  block,
  w,
  h,
  context,
  viewportWidth,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as FigmaEmbedContent
  const showFallback = context === 'SEARCH'

  return (
    <div className="flex h-full items-center justify-center overflow-hidden rounded-page-widget-inner">
      {!showFallback && content?.html && (
        <MemoizedEmbedBlock
          html={content.html}
          w={w}
          h={h}
          color="#000"
          viewportWidth={viewportWidth}
          scaleFactor={scaleFactor}
        />
      )}

      {showFallback && <div className="text-center">{t('figma')}</div>}
    </div>
  )
}
