import { EducationContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { useTranslation } from 'react-i18next'

import { BlockCarousel } from '@/components/shared/block-carousel'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { cn } from '@/lib/utils'
import { getInitials } from '@/lib/utils/string-utils'

import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'

export const EducationBlock = ({ block, blockMeta, h }: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as EducationContent
  const items = content?.schools || []
  const showTitle = h > 6 && content?.title

  return (
    <div className="flex size-full flex-col rounded-page-widget">
      {showTitle && (
        <div className="p-block">
          <div className="page-heading line-clamp-2 grow text-fluid-lg">
            {content.title}
          </div>
        </div>
      )}

      <BlockCarousel
        count={items.length}
        viewportClassName={cn('px-block rounded-b-page-widget-inner', {
          'rounded-t-page-widget-inner': !showTitle,
        })}
        options={{
          startIndex: (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
        }}
      >
        {items.map((item, index) => {
          return (
            <div
              className={cn('flex size-full shrink-0 pb-block', {
                'pr-fluid-3 max-w-fluid-80': items.length > 1,
                'pt-block': !showTitle,
              })}
              key={index}
            >
              <div className="rounded-page-widget-block bg-page-card-background border-page-card shadow-page-card flex w-full flex-col justify-center p-fluid-3 text-left text-page-card-text shadow-page-card">
                {item?.media?.type ? (
                  <MediaImage
                    media={item?.media}
                    className="mb-fluid-1 size-fluid-9! min-w-fluid-9"
                    width={36}
                    height={36}
                    style={{
                      borderRadius: 9999,
                      overflow: 'hidden',
                    }}
                  />
                ) : (
                  <Avatar className="mb-fluid-1 size-fluid-9 min-w-fluid-9">
                    <AvatarFallback
                      value={getInitials(item?.schoolName)}
                      noColor
                    />
                  </Avatar>
                )}

                {item?.schoolName && (
                  <div className="page-heading mb-fluid-1 line-clamp-2 text-page-card-text!">
                    {item?.schoolName}
                  </div>
                )}

                {item?.degreeName && (
                  <div className="mb-fluid-1 line-clamp-2 text-fluid-sm">
                    {item.degreeName}
                  </div>
                )}

                {item?.startYear && (
                  <span className="line-clamp-2 text-fluid-sm opacity-70">
                    {item?.startYear} - {item?.endYear || t('present')}
                  </span>
                )}
              </div>
            </div>
          )
        })}
      </BlockCarousel>
    </div>
  )
}
