import {
  RatingsContent,
  RatingsMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { BlockProps } from '../shared/block-switch'
import { GoogleWithReviews } from './google-with-reviews'
import { WithoutReviews } from './without-reviews'

export const GoogleInner = ({ w, h, block, blockMeta }: BlockProps) => {
  const content = block?.content?.content?.value as RatingsContent
  const meta = blockMeta?.meta?.metaContent?.value as RatingsMeta
  const ratingAverage = meta?.rating || 5
  const ratingCount = meta?.ratingsTotal || 0

  if (w < 3 || h <= 5 || !content?.showReviews || ratingCount === 0) {
    return (
      <a
        href={meta?.ratingsUrl}
        target="_blank"
        className="p-block block size-full"
      >
        <WithoutReviews
          source="GOOGLE"
          h={h}
          w={w}
          ratingsAverage={ratingAverage}
          ratingCount={ratingCount}
        />
      </a>
    )
  }

  return (
    <GoogleWithReviews
      meta={meta}
      h={h}
      w={w}
      ratingsAverage={ratingAverage}
      ratingCount={ratingCount}
    />
  )
}
