import {
  RatingsContent,
  RatingsMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { Session } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'

import { RatingDialog } from '@/components/rating/rating-dialog'
import { getSupabaseClient } from '@/lib/supabase/supabase-client'

import { BlockProps } from '../shared/block-switch'
import { FingertipWithReviews } from './fingertip-with-reviews'
import { WithoutReviews } from './without-reviews'

export const FingertipInner = ({
  w,
  h,
  blockMeta,
  block,
  siteSlug,
}: BlockProps) => {
  const content = block?.content?.content?.value as RatingsContent
  const meta = blockMeta?.meta?.metaContent?.value as RatingsMeta
  const ratingAverage = meta?.rating || 5
  const ratingCount = meta?.ratingsTotal || 0

  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    const getData = async () => {
      const supabase = getSupabaseClient()

      const {
        data: { session },
      } = await supabase.auth.getSession()

      setSession(session)
    }

    getData()
  }, [])

  if (w < 3 || h <= 5 || !content?.showReviews || ratingCount === 0) {
    return (
      <RatingDialog siteSlug={siteSlug} pageSlug="index" session={session}>
        <div className="p-block block size-full cursor-pointer">
          <WithoutReviews
            source="FINGERTIP"
            h={h}
            w={w}
            ratingsAverage={ratingAverage}
            ratingCount={ratingCount}
          />
        </div>
      </RatingDialog>
    )
  }

  return (
    <FingertipWithReviews
      meta={meta}
      h={h}
      w={w}
      ratingsAverage={ratingAverage}
      ratingCount={ratingCount}
      siteSlug={siteSlug}
    />
  )
}
