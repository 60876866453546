/* eslint-disable @next/next/no-img-element */
import { PauseIcon, PlayIcon } from '@fingertip/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

import { SpotifyIconBox } from './spotify-icon-box'
import { SpotifyPlaybackProps } from './web-playback'

type Props = {
  episode: any
} & SpotifyPlaybackProps

export const Episode = ({ episode }: Props) => {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef<null | HTMLAudioElement>(null)

  useEffect(() => {
    audioRef.current = new Audio(episode?.audio_preview_url)
  }, [episode])

  const playAudio = useCallback(() => {
    audioRef?.current?.play()
    setIsPlaying(true)
  }, [])

  const pauseAudio = useCallback(() => {
    audioRef?.current?.pause()
    setIsPlaying(false)
  }, [])

  useEffect(() => {
    return () => {
      audioRef?.current?.pause()
      setIsPlaying(false)
    }
  }, [])

  return (
    <div className="p-block flex size-full items-center">
      {episode?.images?.[0] && (
        <div className="flex items-center justify-center">
          <a
            href={episode?.external_urls?.spotify}
            target="_blank"
            className="relative block"
          >
            <img
              src={episode?.images?.[0]?.url}
              alt={`${episode?.name} episode cover`}
              className="rounded-page-widget-block mr-fluid-6 aspect-square w-fluid-24 min-w-fluid-24 object-cover shadow-sm"
              width={100}
              height={100}
              loading="lazy"
            />

            <SpotifyIconBox />
          </a>
        </div>
      )}

      <div className="flex flex-1 flex-col justify-between">
        <div>
          <a
            href={episode?.external_urls?.spotify}
            target="_blank"
            className="page-heading line-clamp-1 text-fluid-lg hover:underline"
          >
            {episode?.name}
          </a>

          <a
            href={episode?.show?.external_urls?.spotify}
            className="mb-fluid-2 line-clamp-1 hover:underline"
            target="_blank"
          >
            {episode?.show?.name}
          </a>
        </div>

        {episode?.audio_preview_url && (
          <div>
            <Button
              onClick={isPlaying ? pauseAudio : playAudio}
              size="blockSm"
              variant="blockPrimary"
              className="min-w-fluid-24"
            >
              {isPlaying ? (
                <PauseIcon className="mr-fluid-2 size-fluid-4" />
              ) : (
                <PlayIcon className="mr-fluid-2 size-fluid-4" />
              )}
              {isPlaying ? t('pause') : t('play')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
