import { ProductContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { BlockProps } from '../shared/block-switch'
import { ProductInner } from './product-inner'
import { StoreProductInner } from './store-product-inner'

export const ProductBlock = (props: BlockProps) => {
  const content = props?.block?.content?.content?.value as ProductContent

  if (content?.source === 'FINGERTIP') {
    return <StoreProductInner {...props} />
  }

  return <ProductInner {...props} />
}
