import {
  MapContent,
  MapMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import NextImage from 'next/image'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AbsoluteMapLabel } from '@/components/blocks/map/absolute-map-label'
import { GetDirectionsButton } from '@/components/shared/get-directions-button'
import { env } from '@/env'

import { BlockProps } from '../shared/block-switch'
import { getFullHeight, getFullWidth } from '../shared/utils'
import { DEFAULT_ZOOM } from './constants'
import { CustomMapDot } from './map-dot'

export const MapBlock = ({
  block,
  blockMeta,
  handleClick,
  w,
  h,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as MapContent
  const meta = blockMeta?.meta?.metaContent?.value as MapMeta

  const lat = meta?.lat || 0
  const lng = meta?.lng || 0
  const zoom = Math.min(22, Math.max(1, content?.zoom ?? DEFAULT_ZOOM))

  const widgetStyle = useMemo(() => {
    if (h >= w * 2 && h >= 8 && w >= 4) {
      return 'large'
    }
    if (w <= 2) {
      return 'small'
    }
    return 'medium'
  }, [w, h])

  if (!meta || lng === 0 || lat === 0) {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('map_is_missing')}</p>
      </div>
    )
  }

  const WIDTH = getFullWidth(w, scaleFactor)
  const HEIGHT = getFullHeight(h, scaleFactor)

  return (
    <a
      onClick={() => handleClick?.(block)}
      className="relative block size-full overflow-hidden rounded-page-widget-inner bg-white"
      href={meta?.url || '#'}
      target={!!meta?.url ? '_blank' : undefined}
    >
      {/* Map Image */}
      <NextImage
        src={`https://api.mapbox.com/styles/v1/matthewblode/clix8w21k00ux01qi5r472wau/static/${lng},${lat},${zoom},0/${WIDTH}x${HEIGHT}@2x?access_token=${env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}&logo=false&attribution=false`}
        alt={content?.title || ''}
        className="size-full object-cover"
        width={WIDTH}
        height={HEIGHT}
      />

      {/* Title, Address Labels */}
      {(content?.title || meta.locationLabel) && (
        <AbsoluteMapLabel
          size={widgetStyle}
          locationLabel={meta.locationLabel}
          title={content?.title}
        />
      )}

      {/* Fixed Map Pin Icon */}
      <div className="absolute left-1/2 top-1/2 flex size-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
        <CustomMapDot pulsating={true} size={100} scaleFactor={scaleFactor} />
      </div>

      {/* =Get Directions Button */}
      <div className="p-block absolute right-0 top-0 flex">
        <GetDirectionsButton lng={lng} lat={lat} size={widgetStyle} />
      </div>
    </a>
  )
}
