import { useTranslation } from 'react-i18next'

import { weekdayLabels } from '@/components/blocks/opening-hours/constants'
import { Days } from '@/components/blocks/opening-hours/types'
import {
  getTimesText,
  getTimeText,
} from '@/components/blocks/opening-hours/utils'
import { cn } from '@/lib/utils'

type Props = {
  days: Days
  w: number
  h: number
  type: 'WEEKEND' | 'WEEKDAY' | 'BOTH'
  use24Hour?: boolean
}

export const OpeningHoursTable = ({
  days,
  w,
  h,
  type,
  use24Hour = false,
}: Props) => {
  const { t } = useTranslation()
  let currentDayIndex = new Date().getDay() - 1
  if (currentDayIndex === -1) {
    currentDayIndex = 6
  }
  const showSlots = h >= 14

  return (
    <div>
      {days.map((day, index) => {
        const timesText =
          day.length > 1
            ? getTimesText(day, false, '–', use24Hour, t)
            : getTimesText(day, false, '–', use24Hour, t)
        let tmpDay = day
        if (day.length === 0) {
          tmpDay.push({
            start: undefined,
            end: undefined,
          })
        }
        return tmpDay.map((time, timeIndex) => {
          if (!showSlots && timeIndex > 0) {
            return null
          }
          const timeText = getTimeText(time, false, '–', use24Hour, t)
          let filteredDays = true
          if (type === 'WEEKDAY') {
            filteredDays = index < 5
          } else if (type === 'WEEKEND') {
            filteredDays = index >= 5
          }
          return (
            <div
              key={index + ':' + timeIndex}
              className={cn(
                'flex flex-row justify-between items-center leading-none truncate',
                {
                  'font-bold': index === currentDayIndex,
                  hidden: !filteredDays,
                },
              )}
            >
              <div
                className={cn('pr-fluid-2 text-left leading-[1.1]', {
                  'text-center': w < 4,
                  'leading-snug': h >= 6,
                })}
              >
                {w >= 4 && timeIndex === 0 && weekdayLabels[index]}
                {w < 4 && timeIndex === 0 && (
                  <>{weekdayLabels[index].slice(0, 3)}</>
                )}
              </div>
              <div
                className={cn('text-right flex-1 leading-[1.1]', {
                  'leading-snug': h >= 6,
                })}
              >
                {showSlots ? timeText : timesText}
              </div>
            </div>
          )
        })
      })}
    </div>
  )
}
