import { LinkListContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowRightIcon } from '@fingertip/icons'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkList } from '@/components/blocks/link-list/link-list'
import { getFullHeight } from '@/components/blocks/shared/utils'
import { Button } from '@/components/ui/button'
import { useHorizontalVisibleCount } from '@/lib/hooks/use-horizontal-visible-count'
import { cn } from '@/lib/utils/cn'

import { BlockProps } from '../shared/block-switch'

type Props = {
  h: number
  w: number
  content: LinkListContent
  handleOpen: () => void
} & Pick<
  BlockProps,
  | 'block'
  | 'handleClick'
  | 'viewportWidth'
  | 'scaleFactor'
  | 'siteSlug'
  | 'domain'
>

export const LinkListInner = ({
  siteSlug,
  domain,
  h,
  content,
  block,
  handleClick,
  handleOpen,
  scaleFactor,
}: Props) => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const items = content?.links || []
  const hasTitle = !!content?.title
  const HEIGHT = getFullHeight(h, scaleFactor)
  const isShort = h <= 4

  const { visibleCount, showButton } = useHorizontalVisibleCount({
    containerHeight: HEIGHT,
    linkHeight: 48,
    containerRef,
    titleRef,
    items,
    buttonHeight: isShort ? 36 : 48,
    scaleFactor,
  })

  return (
    <>
      <div ref={titleRef}>
        {hasTitle && (
          <div className="mb-fluid-2 shrink-0">
            <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
              {content.title}
            </div>
          </div>
        )}
      </div>

      <div
        className="flex w-full flex-1 flex-col overflow-hidden"
        ref={containerRef}
      >
        <LinkList
          handleClick={handleClick}
          block={block}
          items={items}
          visibleCount={visibleCount}
          siteSlug={siteSlug}
          domain={domain}
        />
      </div>

      {showButton && (
        <div
          className={cn({
            'w-full': !isShort,
          })}
        >
          <Button
            size={isShort ? 'blockSm' : 'block'}
            variant="blockPrimary"
            className={cn({
              'w-full': !isShort,
            })}
            onClick={handleOpen}
          >
            {t('view_all')} {items.length}
            <ArrowRightIcon className="ml-fluid-2 size-fluid-4" />
          </Button>
        </div>
      )}
    </>
  )
}
