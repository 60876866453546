import { ProductContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowUpRightIcon, Basket2Icon } from '@fingertip/icons'
import { useTranslation } from 'react-i18next'

import { BlockCarousel } from '@/components/shared/block-carousel'
import { prependUrl } from '@/lib/utils/utils'

import { BlockAssetInner } from '../shared/block-asset-inner'
import { BlockProps } from '../shared/block-switch'

export const ProductInner = ({
  siteSlug,
  domain,
  block,
  blockMeta,
  w,
  h,
  y,
  handleClick,
  isPreview,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as ProductContent
  const products = content?.products

  const fallbackProductName = isPreview ? t('enter_a_name') : ''

  if (!products || products.length === 0) {
    return null
  }

  return (
    <BlockCarousel
      count={products.length}
      viewportClassName="rounded-page-widget-inner"
      options={{
        startIndex: (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
      }}
    >
      {products.map((product, index) => {
        let buttonText = t('buy')
        if (product?.productPrice) {
          buttonText = product.productPrice
        }

        return (
          <a
            onClick={() => handleClick?.(block, product?.productName)}
            href={prependUrl(product?.websiteUrl, { siteSlug, domain })}
            target={!!product?.websiteUrl ? '_blank' : undefined}
            className="relative flex size-full text-left"
            key={index}
          >
            <BlockAssetInner
              media={product?.media}
              title={product?.productName || fallbackProductName}
              description={product?.productDescription}
              buttonText={buttonText}
              icon={<Basket2Icon className="size-fluid-4" />}
              w={w}
              h={h}
              y={y}
              buttonIcon={
                <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
              }
              scaleFactor={scaleFactor}
            />
          </a>
        )
      })}
    </BlockCarousel>
  )
}
