import { RatingsContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { Session } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'

import { RatingDialog } from '@/components/rating/rating-dialog'
import { getSupabaseClient } from '@/lib/supabase/supabase-client'
import { prependUrl } from '@/lib/utils/utils'

import { BlockProps } from '../shared/block-switch'
import { WithoutReviews } from './without-reviews'

export const CustomInner = ({ w, h, block, siteSlug, domain }: BlockProps) => {
  const content = block?.content?.content?.value as RatingsContent
  const ratingAverage = content?.customRating || 5
  const ratingCount = content?.customRatingsTotal || -1

  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    if (!!content?.customRatingsUrl) {
      return
    }

    const getData = async () => {
      const supabase = getSupabaseClient()

      const {
        data: { session },
      } = await supabase.auth.getSession()

      setSession(session)
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!content?.customRatingsUrl) {
    return (
      <RatingDialog siteSlug={siteSlug} pageSlug="index" session={session}>
        <div className="p-block block size-full cursor-pointer">
          <WithoutReviews
            source="CUSTOM"
            h={h}
            w={w}
            ratingsAverage={ratingAverage}
            ratingCount={ratingCount}
          />
        </div>
      </RatingDialog>
    )
  }

  return (
    <a
      href={prependUrl(content?.customRatingsUrl, { siteSlug, domain })}
      target="_blank"
      className="p-block block size-full"
    >
      <WithoutReviews
        source="CUSTOM"
        h={h}
        w={w}
        ratingsAverage={ratingAverage}
        ratingCount={ratingCount}
      />
    </a>
  )
}
