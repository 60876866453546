import type { GalleryContentAsset } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { MediaImage } from '@/components/blocks/shared/media-image'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'

const calculateDimensions = (
  asset: GalleryContentAsset,
  maxWidth = 1000,
  maxHeight = 1000,
) => {
  const originalWidth = asset?.media?.width || maxWidth
  const originalHeight = asset?.media?.height || maxHeight
  const aspectRatio = originalWidth / originalHeight

  let width = Math.min(originalWidth, maxWidth)
  let height = Math.round(width / aspectRatio)

  if (height > maxHeight) {
    height = maxHeight
    width = Math.round(height * aspectRatio)
  }

  return { width, height, aspectRatio }
}

type Props = {
  assets: GalleryContentAsset[]
  index: number
}

export const LightboxGallery = ({ assets, index }: Props) => {
  if (assets.length === 1) {
    const asset = assets[0]
    const { width, height } = calculateDimensions(asset)

    return (
      <div className="flex size-full items-center justify-center bg-black">
        <MediaImage
          width={width}
          height={height}
          media={asset?.media}
          title={asset?.title}
          objectFit="CONTAIN"
          className="size-full"
          style={{
            maxWidth: width,
            maxHeight: height,
          }}
        />
      </div>
    )
  }

  return (
    <div className="max-h-[calc(100vh-100px)] max-w-[100vw] bg-black sm:max-w-[90vw]">
      <Carousel className="size-full" opts={{ startIndex: index, loop: true }}>
        <CarouselContent className="size-full" refClassName="size-full">
          {assets.map((asset, index) => {
            const { width, height } = calculateDimensions(asset)

            return (
              <CarouselItem
                key={index}
                className="flex size-full items-center justify-center"
              >
                <MediaImage
                  width={width}
                  height={height}
                  media={asset?.media}
                  title={asset?.title}
                  objectFit="CONTAIN"
                  className="size-full"
                  style={{
                    maxWidth: width,
                    maxHeight: height,
                  }}
                />
              </CarouselItem>
            )
          })}
        </CarouselContent>

        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  )
}
