import { GalleryContentAsset } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowUpRightIcon } from '@fingertip/icons'

import { ButtonDiv } from '@/components/ui/button'

import { MediaImage } from '../shared/media-image'
import { getFullHeight, getFullWidth } from '../shared/utils'

type Props = {
  w: number
  h: number
  y: number
  index: number
  asset: GalleryContentAsset
  viewportWidth: number
  scaleFactor: number
  hideBackground?: boolean
}

export const GalleryItem = ({
  asset,
  h,
  w,
  y,
  index,
  scaleFactor,
  hideBackground,
}: Props) => {
  const WIDTH = getFullWidth(w, scaleFactor)
  const HEIGHT = getFullHeight(h, scaleFactor)

  let gradient = (36 / HEIGHT) * 100

  return (
    <>
      <MediaImage
        width={WIDTH}
        height={HEIGHT}
        media={asset?.media}
        title={asset?.title}
        priority={index === 0 && y < 6}
        objectFit={hideBackground ? 'CONTAIN' : 'COVER'}
      />

      {asset?.title && (
        <>
          <div
            className="pointer-events-none absolute bottom-0 left-0 z-10 size-full rounded-page-widget-inner"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.35) ${gradient / 2}%, rgba(0,0,0,0) ${gradient}%)`,
            }}
          />
          <div className="px-block pb-block absolute bottom-0 left-0 z-20 block w-full text-fluid-sm text-white!">
            <div className="line-clamp-1">{asset?.title}</div>
          </div>
        </>
      )}

      {!!asset?.websiteUrl && w > 1 && (
        <div className="p-block absolute right-0 top-0 flex">
          <ButtonDiv
            variant="blockSecondary"
            size="blockSm"
            className="h-auto! p-fluid-1!"
          >
            <ArrowUpRightIcon className="size-fluid-4" />
          </ButtonDiv>
        </div>
      )}
    </>
  )
}
