import { FormMeta } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import {
  ArrowRightIcon,
  ArrowsRepeatRightLeftIcon,
  SquareLinesIcon,
} from '@fingertip/icons'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { BlockAssetInner } from '@/components/blocks/shared/block-asset-inner'
import { FormPage } from '@/components/form-template/form-page'
import { DialogContent, DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'

import { BlockProps } from '../shared/block-switch'
import { FormStages } from './types'

export const FormBlock = ({
  block,
  blockMeta,
  h,
  w,
  y,
  siteSlug,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<FormStages>('NOT_STARTED')
  const [dialogOpen, setDialogOpen] = useState(false)

  const meta = blockMeta?.meta?.metaContent?.value as FormMeta
  const formTemplateSlug = meta?.formTemplate?.slug

  const onOpenDialogChange = useCallback((value: boolean) => {
    if (value) {
      setStatus('STARTED')
    } else {
      setStatus('NOT_STARTED')
    }

    setDialogOpen(value)
  }, [])

  if (status === 'NOT_STARTED') {
    return (
      <a
        onClick={() => {
          if (!formTemplateSlug) {
            toast.error(
              'Form is currently unavailable. Please try again later.',
            )
            return
          }
          setStatus('STARTED')
          setDialogOpen(true)
        }}
        className="relative flex size-full cursor-pointer"
      >
        <BlockAssetInner
          media={meta?.formTemplate?.bannerMedia}
          title={meta?.formTemplate?.title}
          description={meta?.formTemplate?.description}
          buttonText={meta?.formTemplate?.buttonText || t('start_form')}
          icon={<SquareLinesIcon className="size-fluid-4 shrink-0" />}
          w={w}
          h={h}
          y={y}
          buttonIcon={
            <ArrowRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
          }
          scaleFactor={scaleFactor}
        />
      </a>
    )
  }

  if (status === 'SUBMITTED') {
    return (
      <a
        onClick={() => setStatus('NOT_STARTED')}
        className="relative flex size-full cursor-pointer"
      >
        <BlockAssetInner
          media={meta?.formTemplate?.bannerMedia}
          title={
            meta?.formTemplate?.thankYouMessage ||
            t('thank_you_for_your_submission')
          }
          buttonText={t('reload')}
          icon={<SquareLinesIcon className="size-fluid-4 shrink-0" />}
          w={w}
          h={h}
          y={y}
          buttonIcon={
            <ArrowsRepeatRightLeftIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
          }
          scaleFactor={scaleFactor}
        />
      </a>
    )
  }

  return (
    <ResponsiveSheet
      open={dialogOpen}
      modal={true}
      onOpenChange={onOpenDialogChange}
      defaultOpen={true}
    >
      <DialogContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
        <VisuallyHidden>
          <DialogTitle>Form</DialogTitle>
        </VisuallyHidden>

        <div className="mt-fluid-4">
          <FormPage
            onSuccess={() => setStatus('SUBMITTED')}
            formTemplateSlug={formTemplateSlug!}
            source={`blockId:${block.id}`}
            siteSlug={siteSlug}
          />
        </div>
      </DialogContent>
    </ResponsiveSheet>
  )
}
