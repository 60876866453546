/* eslint-disable @next/next/no-img-element */
import { cn } from '@/lib/utils'

import { SpotifyIconBox } from './spotify-icon-box'
import { SpotifyPlaybackProps } from './web-playback'

type Props = {
  audiobook: any
} & SpotifyPlaybackProps

export const Audiobook = ({ audiobook, h }: Props) => {
  const isShort = h <= 4

  return (
    <div className="p-block relative flex size-full flex-col overflow-hidden">
      <div className="mb-fluid-3 flex items-center">
        {audiobook.images[0] && (
          <a
            href={audiobook?.external_urls?.spotify}
            target="_blank"
            className="relative block"
          >
            <img
              src={audiobook.images[0].url}
              alt={`${audiobook.name} audiobook cover`}
              className={cn(
                'aspect-square w-fluid-24 min-w-fluid-24 rounded-page-widget-block object-cover shadow-sm',
                {
                  'mr-fluid-6': !isShort,
                },
              )}
              width={100}
              height={100}
              loading="lazy"
            />

            <SpotifyIconBox />
          </a>
        )}

        <div className="w-[calc(100%-124px)]">
          <a
            href={audiobook?.external_urls?.spotify}
            target="_blank"
            className="page-heading line-clamp-1 text-fluid-lg hover:underline"
          >
            {audiobook.name}
          </a>

          <p className="mb-fluid-2 line-clamp-1">{audiobook.description}</p>
        </div>
      </div>

      {!isShort && (
        <div className="overflow-y-auto">
          {(audiobook?.tracks?.items || []).map((item: any, index: number) => (
            <div key={index} className="mb-fluid-2 line-clamp-1 text-fluid-sm">
              {item?.track?.name} by{' '}
              {item?.track?.artists
                .map((artist: any) => artist?.name)
                .join(', ')}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
