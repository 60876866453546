import { BlogPostStatus } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/blog_post_status_pb'
import {
  BlogPostContent,
  BlogPostMeta,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowRightIcon, EditBigIcon } from '@fingertip/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockCarousel } from '@/components/shared/block-carousel'
import { DialogTitle } from '@/components/ui/dialog'
import { ResponsiveSheet } from '@/components/ui/responsive-sheet'
import { ResponsiveSheetContent } from '@/components/ui/responsive-sheet-content'
import { useBreakpoint } from '@/lib/hooks/use-breakpoint'

import { BlockAssetInner } from '../shared/block-asset-inner'
import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'

export const BlogPostBlock = ({
  block,
  blockMeta,
  w,
  h,
  y,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const content = block?.content?.content?.value as BlogPostContent
  const meta = blockMeta?.meta?.metaContent?.value as BlogPostMeta
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [selectedPostId, setSelectedPostId] = useState<string | undefined>()

  const filteredPosts = useMemo(() => {
    if ((content?.blogPostIds || []).length === 0) {
      return meta?.posts || []
    }

    return (meta?.posts || []).filter(
      (post) =>
        content?.blogPostIds.includes(post?.post?.id || '') &&
        ['BLOG_POST_STATUS_PUBLIC', 'PUBLIC', BlogPostStatus.PUBLIC].includes(
          post?.post?.status as any,
        ),
    )
  }, [content?.blogPostIds, meta?.posts])

  if (meta && filteredPosts.length === 0) {
    return (
      <div className="p-block flex size-full items-center justify-center">
        <p>{t('blog_is_coming_soon')}</p>
      </div>
    )
  }

  const post = filteredPosts.find(
    (post) => post?.post?.id === selectedPostId,
  )?.post

  return (
    <>
      <div className="relative size-full rounded-page-widget-inner">
        <BlockCarousel
          count={filteredPosts.length}
          viewportClassName="rounded-page-widget-inner"
          options={{
            startIndex:
              (blockMeta?.meta?.metaContent?.value as any)?.index || 0,
          }}
        >
          {filteredPosts.map((post) => {
            return (
              <button
                type="button"
                className="relative flex size-full cursor-pointer shrink-0 text-left"
                key={post?.post?.id || ''}
                onClick={() => {
                  setSelectedPostId(post?.post?.id)
                  setDialogOpen(true)
                }}
              >
                <BlockAssetInner
                  media={post?.post?.featureImage}
                  title={post?.post?.title}
                  description={post?.post?.excerpt}
                  buttonText={content?.buttonText || t('read_more')}
                  icon={<EditBigIcon className="size-fluid-4" />}
                  w={w}
                  h={h}
                  y={y}
                  buttonIcon={
                    <ArrowRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
                  }
                  scaleFactor={scaleFactor}
                />
              </button>
            )
          })}
        </BlockCarousel>
      </div>

      <ResponsiveSheet
        open={dialogOpen}
        modal={breakpoint !== 'sm'}
        onOpenChange={(open) => {
          setDialogOpen(open)
        }}
      >
        <ResponsiveSheetContent
          desktopClassName="max-h-full overflow-auto"
          mobileClassName="w-full max-h-[calc(100vh-100px)] min-w-full overflow-auto"
        >
          <div className="p-fluid-4">
            <DialogTitle className="h1 mb-fluid-4">
              {post?.title || ''}
            </DialogTitle>

            {post?.featureImage && (
              <div className="mb-fluid-4">
                <MediaImage
                  width={post?.featureImage?.width || 500}
                  height={post?.featureImage?.height || 500}
                  media={post?.featureImage}
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
            )}

            <div
              className="message-editor prose prose-base w-full text-foreground prose-headings:text-foreground prose-p:text-foreground prose-a:text-foreground prose-a:underline prose-strong:text-foreground prose-em:text-foreground"
              dangerouslySetInnerHTML={{
                __html: post?.html || '',
              }}
            />
          </div>
        </ResponsiveSheetContent>
      </ResponsiveSheet>
    </>
  )
}
