import { PortfolioContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowUpRightIcon } from '@fingertip/icons'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useState } from 'react'

import { ConditionalWrapper } from '@/components/shared/conditional-wrapper'
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { prependUrl } from '@/lib/utils/utils'

import { LightboxGallery } from '../gallery/lightbox-gallery'
import { BlockProps } from '../shared/block-switch'
import { MediaImage } from '../shared/media-image'

export const PortfolioBlock = ({
  siteSlug,
  domain,
  block,
  handleClick,
  h,
  y,
}: BlockProps) => {
  const content = block?.content?.content?.value as PortfolioContent
  const hasTitle = !!content?.title && h > 4
  const assets = content?.assets || []
  const [index, setIndex] = useState<number | null>(null)

  return (
    <>
      <div
        className={cn(
          'flex p-block size-full overflow-hidden flex-col justify-center',
          {
            'justify-between': !hasTitle,
          },
        )}
      >
        {hasTitle && (
          <div className="mb-fluid-3 flex">
            <div className="page-heading line-clamp-2 grow text-left text-fluid-lg">
              {content.title}
            </div>
          </div>
        )}

        <div className="flex flex-col gap-fluid-2 overflow-auto">
          <div
            className={cn('grid w-full grid-cols-3 gap-fluid-3', {
              'mt-fluid-1': !hasTitle,
            })}
          >
            {assets.map((asset, index) => {
              return (
                <div
                  key={index}
                  className="rounded-page-widget-block relative overflow-hidden"
                >
                  <ConditionalWrapper
                    condition={!!asset.websiteUrl}
                    wrapper={(children) => (
                      <a
                        href={prependUrl(asset?.websiteUrl, {
                          siteSlug,
                          domain,
                        })}
                        target={!!asset?.websiteUrl ? '_blank' : undefined}
                        onClick={() => handleClick?.(block, 'portfolio')}
                        key={index}
                        className="size-full"
                      >
                        {children}
                      </a>
                    )}
                  >
                    <>
                      {asset?.websiteUrl ? (
                        <div className="relative size-full shrink-0">
                          <MediaImage
                            media={asset?.media}
                            title={asset?.title}
                            width={112}
                            height={112}
                            priority={y < 6}
                            className="h-fluid-28!"
                          />
                        </div>
                      ) : (
                        <div
                          className="relative size-full shrink-0 cursor-pointer"
                          onClick={() => setIndex(index)}
                        >
                          <MediaImage
                            media={asset?.media}
                            title={asset?.title}
                            width={112}
                            height={112}
                            priority={y < 6}
                            className="h-fluid-28!"
                          />
                        </div>
                      )}

                      {!!asset.websiteUrl && (
                        <div className="absolute right-0 top-0 flex p-fluid-1">
                          <div className="h-auto! rounded-page-widget-4! p-fluid-1! text-white! bg-background/50">
                            <ArrowUpRightIcon className="size-fluid-4" />
                          </div>
                        </div>
                      )}
                    </>
                  </ConditionalWrapper>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {index !== null && (
        <Dialog
          open={index !== null}
          onOpenChange={(open) => setIndex(open ? 0 : null)}
        >
          <DialogContent className="max-h-[calc(100vh-100px)] max-w-[100vw] overflow-y-auto p-0 sm:max-w-[90vw]">
            <VisuallyHidden>
              <DialogTitle>Portfolio</DialogTitle>
            </VisuallyHidden>

            <LightboxGallery assets={assets} index={index} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
